.input-container {
  .labelinput {
    font-size: 12px;
    font-weight: 600;
    color: var(--tertiary-text-color) !important;
    padding-bottom: 4px;
  }

  .input-text {
    display: block;
    width: 100%;
    border: 1px solid var(--primary-border-color);
    border-radius: 8px;
    padding: 15.5px 12px;
    outline: none;

    font-size: 14px;
    color: var(--primary-text-color);
    background: transparent;
    line-height: 14px;

    &:focus {
      border-color: var(--secondary-border-color);
    }
  }
}