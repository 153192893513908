.form-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--tertiary-text-color) !important;
  padding-bottom: 4px;
  margin: 0;
}

.form-control {
  display: block;
  width: 100%;
  border: 1px solid var(--primary-border-color);
  border-radius: 8px;
  padding: 15.5px 12px;
  outline: none;

  font-size: 14px;
  color: var(--primary-text-color) !important;
  background: transparent !important;
  line-height: 14px;

  &:focus {
    border-color: var(--secondary-border-color);
    box-shadow: none !important;
  }
}

.rte-editor {
  background-color: var(--secondary-background-color) !important;
}

.rte-editor-readonly {
  border: none !important;
}