.how-it-works {
    color: #fff;
    padding: 4rem 0;
  }
  
 
  
  .header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .label {
    display: inline-block;
    background-color: rgba(154, 230, 80, 0.1);
    color: #9ae650;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .title {
    font-size: 44px;
    font-weight: 400;
    max-width: 900px;
    margin: 0 auto;
    color:rgb(204, 204, 204);

  }
  
  .cards {
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 32px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1280px;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%;
  }
  
  .card {
    background-color: #1a1a1a;
    border-radius: 1rem;
    padding: 2rem;
    flex: 1;
    border-radius: 24px;
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 40px 32px;
    position: relative;
    /* width: 1px; */
    border-bottom-width: 1px;
    align-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: 330px;
  }
  
  .icon {
    color: #9ae650;
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  
  .card h3 {
    font-size: 36px;
    margin-bottom: 1rem;
    color:rgb(204, 204, 204)
  }
  
  .card p {
    color: #999;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .cards {
      flex-direction: column;
    }
  }
  
  