.upload {
  border: 0.5px solid gray;
  border-radius: 25px;
  align-items: center;
  text-align: center;
  padding: 10px;
}
hr {
  border: 1px solid #E2E8F0;
  opacity: 1;
  margin: 12px 0px;
}
.form-control {
  border-radius: 10px !important;
}
