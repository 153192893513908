// @use "../typography" as typography;

body {
  #react-confirm-alert {
    .react-confirm-alert-overlay {
      background-color: #131514;

      .react-confirm-alert-body {
        // @include regularC1;
        background: var(--Neutral-Greys-50, #191c1b);
        box-shadow: 0px 8px 20.1px 0px #00000040;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;

        h1 {
          // @include semiboldH5;
          color: #e2f0e7;
          font-family: "Inter";
          font-size: 20px;
          font-weight: 600;
          line-height: 24.2px;
        }

        .btnSkipOutline {
          background-color: transparent;
          color: #b4ff45;
        }

        .btnSkipPrimary {
          background-color: #b4ff45;
          color: #131514;
        }

        .btnSkipOutline,
        .btnSkipPrimary {
          border: 1px solid #b4ff45;
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 600;
          // line-height: 28px;
        }
      }
    }
  }
}
