.candidates-main-container {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  & .filter-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    align-items: center;

    & .filter-section {
      display: flex;
      width: 60%;

      & .refresh-list {
        display: flex;
        align-items: center;

        & img {
          -webkit-filter: invert(95%) sepia(40%) saturate(1129%) hue-rotate(24deg) brightness(98%) contrast(106%);
          filter: invert(95%) sepia(40%) saturate(1129%) hue-rotate(24deg) brightness(98%) contrast(106%);
        }
      }

      & .dropdown-menu {
        width: 200px;

        &.show {
          width: auto;
          display: flex;
          flex-direction: column;
          background-color: black;
          color: #868c8a;
          max-height: 500px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background: #191C1B;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: #404342;
            border-radius: 4px;

            &:hover {
              background: #868C8A;
            }
          }

          .dropdown-item {
            height: 40px;
            padding: 12px;

            &:hover {
              background-color: #191c1b;
              color: #868c8a;
            }
          }
        }
      }
    }

  }

  & .no-list-container {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    // text-align: center;
    height: 80vh;
    width: 100%;

    & .no-list-text {
      font-size: 16px;
      font-weight: 600;
    }

    & .text-description {
      font-size: 14px;
    }


    .badge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 9999px;
      background-color: #1c2422;
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
      color: #9ca3af;
    }

    h3 {
      margin-top: 1rem;
      font-size: 0.875rem;
      font-weight: 500;
      color: #d1d5db;
    }

    p {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      color: #6b7280;
    }
  }

  .candidate-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    width: 100%;

    .candidate-card {
      gap: 1em;
      border-radius: 0.25rem;
      background-color: #1c2422;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      height: 122px;
      border-radius: 16px;
      border: solid 1px #2D2E2E;

      .score {
        color: #B4FF45;
      }

      .candidate-info {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        flex: 0.75;

        .avatar {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 9999px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 500;

          &.orange {
            background-color: #f97316;
          }

          &.green {
            background-color: #22c55e;
          }

          &.purple {
            background-color: #a855f7;
          }

          &.blue {
            background-color: #3b82f6;
          }
        }

        .details {
          h3 {
            color: #e5e7eb;
            font-weight: 500;
          }

          .contact-info {
            display: flex;
            gap: 1rem;
            font-size: 0.875rem;
            color: #60a5fa;
          }

          .tags {
            display: flex;
            gap: 0.5rem;
            margin-top: 0.5rem;
            border-radius: 4px;

            .tag {
              padding: 0.125rem 0.5rem;
              font-size: 0.75rem;
              border-radius: 0.25rem;
              background-color: #2D2E2E;
              color: #868C8A;
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        button {
          padding: 0.375rem 1rem;
          font-size: 0.875rem;
          border-radius: 0.25rem;
          font-weight: 500;
          height: 41px;
          border-radius: 8px;

          &.view-video {
            justify-content: center;

            &:hover {
              background-color: #9FE53C !important;
              color: #000 !important;
            }
          }

          &.view-resume {
            border: 1px solid #2D2E2E;
            color: #E2F0E7;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;

            &:hover {
              background-color: #444444 !important;
            }
          }
        }
      }
    }


  }

  & .candidatePagination {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 60%;

    .paginationNoBtns,
    .paginationNextPrevBtn {
      display: flex;
      gap: 6px;
    }

    .paginationNextPrevBtn {
      button {
        border: 1px solid #f8fafc;
      }
    }

    .paginationNoBtns {
      .active {
        color: #eff6ff;
        border: 1px solid #b4ff45;
      }
    }

    button {
      width: 32px;
      height: 32px;
      background-color: #191c1b;
      color: #9b9e9d;
      border-radius: 8px;
      padding: 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 40%;
      }
    }
  }
}