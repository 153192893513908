.rich-text-editor {
  width: 100%;
  border-radius: 0.5rem;
  /* background-color: #1a2426; */
  color: #e5e7eb;
}

.editor-container {
  min-height: 200px;
  padding: 1rem;
  background-color: #131514;
  border: solid 1px #2d2e2e;
  border-radius: 8px;
}

.editor-container .public-DraftEditor-content {
  min-height: 200px;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding: 0.25rem;
  background-color: #131514;
}

.toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem;
  background-color: transparent;
  color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.toggle:hover {
  background-color: #374151;
}

.toggle.active {
  background-color: #4b5563;
  color: #ffffff;
}

.toggle:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.public-DraftEditor-content {
  color: #e5e7eb;
}

.public-DraftEditorPlaceholder-root {
  color: #9ca3af;
}

.public-DraftStyleDefault-unorderedListItem {
  margin-left: 20px;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}
