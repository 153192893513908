.editor-container-main {
  height: 100vh;
  display: flex;
  flex-direction: column;

  // Ensure nav takes minimal space
  &>div:first-child {
    flex: 0 0 auto;
  }

  // Main content area
  &>div:nth-child(2) {
    flex: 1;
    min-height: 0; // Important for Firefox
    display: flex;
    flex-direction: column;
  }

  .tab-header {
    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #404342;
    height: 42px;
    background-color: #2D2E2E;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  .editor-body {
    flex: 1;
    min-height: 0; // Important for Firefox
    // background-color: var(--secondary-background-color);
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 10px;
    margin-top: 0;
    & .left-section {
      display: flex;
      width: 40%;
      margin: 0; // Remove margin to save space

      & .question-main {
        display: flex;
        flex-direction: column;
        border: solid 1px #404342;
        background-color: #191C1B;
        width: 100%;
        border-radius: 16px;

        // Make question content scrollable if needed
        & .tw-p-5 {
          flex: 1;
          overflow-y: auto;
          padding: 10px;
        }
      }
    }

    & .right-section {
      display: flex;
      width: 60%;
      margin: 0; // Remove margin to save space
      flex-direction: column;
      gap: 10px;

      & .code-editor {
        width: 100%;
        height: 90%;
        border: solid 1px #404342;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        & .all-languages {
          border: 1px solid #404342;
          height: 52px;
          background-color: #191C1B;
          width: 100%;
          display: flex;
          align-items: center;
          // padding: 0 16px;
          // margin-top: 15px
        }

        & .all-languages {
          width: 100%;
          & .viewAllQnPageDropdown {
            border: none;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            position: absolute;
            z-index: 9;

          }

          & .dropdown-menu {
            &.show {
              display: flex;
              flex-direction: column;
              background-color: black;
              color: #868c8a;
              height: 250px;
              overflow-y: scroll;

              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                background: #191C1B;
                border-radius: 4px;
              }

              &::-webkit-scrollbar-thumb {
                background: #404342;
                border-radius: 4px;

                &:hover {
                  background: #868C8A;
                }
              }

              .dropdown-item {
                height: 40px;
                padding: 12px;

                &:hover {
                  background-color: #191c1b;
                  color: #868c8a;
                }
              }
            }
          }
        }
      }
    }

    // Make code editor take remaining space
    &>div:last-child {
      flex: 1;
      min-height: 0;
    }
  }

  & .test-case-container {
    overflow: auto;
    height: 50%;
  }

  .action-buttons-container {
    height: 40px;
    min-height: 50px;
    padding: 0 30px;
    border-top: solid 1px #404342;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    // background-color: #191C1B;
  }

  button {
    padding: 8px 16px;
    height: 32px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    min-width: fit-content;
    transition: background-color 0.3s ease;
  }

  .run-button {
    background-color: transparent !important;
    color: #E2F0E7 !important;
    border: solid 1px #868C8A;

    &:hover {
      background-color: #444444 !important;
    }
  }

  .submit-button {
    background-color: #B4FF45;
    color: #000;

    &:hover {
      background-color: #9FE53C !important;
      color: #000 !important;
    }

    &:disabled {
      background-color: #4a4a4a;
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}

// Optimize buttons
button {
  padding: 8px 16px;
  height: 32px;
  display: flex;
  align-items: center;
}

// Make test cases more compact
.accordion-button {
  padding: 8px 12px;
}

// Optimize terminal display
.terminal {
  padding: 10px;
  max-height: 100%;
  overflow-y: auto;
}

.language-selector {
  margin-top: 5px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: rgb(29, 28, 28);
  color: white;
  width: 150px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 20px;
}

.language-selector:hover {
  border-color: #80bdff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  /* Reduce the shadow on hover */
}

.language-selector:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: none;
}

.terminal {
  background-color: var(--secondary-background-color);
  color: #0f0;
  padding: 20px;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  /* border-radius: 10px; */

  width: 100%;
  height: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-y: auto;
}

.terminal-input {
  color: #0f0;
  background-color: #000;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  /* Or specify a fixed height if you prefer */
  white-space: pre-wrap;
  /* Preserve newlines and spaces */
  overflow: auto;
  /* Allow scrolling if content exceeds the height */
  resize: none;
  /* Optional: Disallow user resizing */
}

/* This ensures that the caret color is the same as the text color */
.terminal-input::caret {
  color: #0f0;
}

.accordion-button {
  width: 98%;
}

.accordion-item {
  background: #272822;
}

.accordion-button {
  background: #272822;
  color: white;
}

.accordion-button::after {
  background-image: url("../../public/whiteicon.png");
}

button:hover {
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
}

submit-button {
  margin-left: 20rem;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.codeeditorcard {
  background-color: #272822 !important;
  color: white !important;
  height: 100vh !important;
}


.codeeditorcard p {
  color: white !important;
}

.ace_gutter {
  z-index: 0 !important;
}

.code_running_spinner {
  margin-left: 20px;
  height: 26px;
  margin-bottom: -5px;
}

.terminal-content {
  color: rgb(1, 236, 197);
  font-weight: bold;
  font-size: 15px;
}

.terminal-content-failed {
  color: rgb(236, 103, 1);
  font-weight: bold;
  font-size: 15px;
}

.passed-tests {
  color: rgb(138, 236, 1);
  font-weight: bold;
  font-size: 15px;
}

.failed-tests {
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.hidden {
  display: none;
}

.all-testcases-passed {
  color: rgb(106, 173, 12);
  font-weight: bold;
  font-size: 15px;
}





.all-testcases-failed {
  color: rgb(236, 1, 1);
  font-weight: bold;
  font-size: 15px;
}

.some-testcases-failed {
  color: rgb(236, 111, 1);
  font-weight: bold;
  font-size: 15px;
}

button {
  border: none;
  border-radius: 8px;
  /* Rounded corners */
  padding: 10px 20px;
  /* Padding for a modern look */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  min-width: fit-content;
  transition: background-color 0.3s ease;
}
// run and submit button modified
.run-button {
  background-color: transparent !important;
  color: #E2F0E7 !important;
  border: solid 1px #868C8A;
}

.run-button:hover {
  background-color: #444444 !important;
}

.submit-button {
  background-color: #B4FF45;
  color: #000;
  // margin-left: 20rem;
}

.submit-button:hover {
  background-color: #9FE53C !important;
  color: #000 !important;
}
// .test-pagination-btn {
//   display: flex;            /* Enables Flexbox for alignment */
//   justify-content: vertical;  /* Centers content horizontally */
//   align-items: center;      /* Centers content vertically */
//   width: 40px;              /* Set a fixed width */
//   height: 40px;             /* Set a fixed height */
//   border: 1px solid #ccc;   /* Optional: Add a border */
//   border-radius: 4px;       /* Optional: Rounded corners */
//   font-size: 16px;          /* Adjust the font size */
//   margin: 0 5px;            /* Add space between buttons */  /* Optional: Background color */
//   cursor: pointer;          /* Add pointer for better UX */
// }

// .test-pagination-btn.active {
//   background-color: #28c244; /* Highlight active button */
//   color: white;              /* Text color for contrast */
//   font-weight: bold;         /* Bold for emphasis */
// }

.w-split {
  border: none;
  gap: 5px;

  &-vertical {
    >.w-split-bar {
      width: 100%;
      height: 10px;
      background-color: transparent;
      cursor: row-resize;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      // margin: 5px 0;

      &::before {
        display: none;
      }

      &::after {
        content: '';
        width: 50px;
        height: 4px;
        background-color: #9B9E9D;
        border-radius: 4px;
        border: none;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  &-horizontal {
    >.w-split-bar {
      height: 100%;
      width: 10px;
      background-color: transparent;
      cursor: col-resize;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      // margin: 0 5px;

      &::before {
        display: none;
      }

      &::after {
        content: '';
        height: 50px;
        width: 4px;
        background-color: #9B9E9D;
        border-radius: 4px;
        border: none;
        margin-top: 170px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.right-section {
  overflow: hidden;
}

.code-editor, .test-case-container {
  overflow: auto;
}