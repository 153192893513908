.tab-container {
  border-radius: 16px;
  border: 1px solid #404342;
  background-color: #191c1b;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab-color {
  background-color: #2d2e2e;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  height: 42px;
  border-radius: 16px 16px 0 0;
  border: 1px solid #404342;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 24px;
  flex-shrink: 0;
}

.content-container {
  flex: 1;
  /* padding: 12px; */
  overflow: hidden;
}

.line-numbered-container {
  display: flex;
  height: 100%;
  /* background-color: #2d2e2e; */
  border-radius: 12px;
}

.test-case-line-numbers {
  padding: 12px 8px;
  background-color: #262828;
  border-radius: 12px 0 0 12px;
  color: #868c8a;
  font-family: monospace;
  text-align: right;
  user-select: none;
}

.line-number {
  padding: 0 8px;
  font-size: 14px;
  line-height: 1.5;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.current-line {
  background-color: #3a3f3d;
  color: #ffffff;
  font-weight: 500;
  border-radius: 4px;
}

.code-content {
  flex: 1;
  margin: 0;
  padding: 12px;
}

.tab-item {
  text-align: center;
  cursor: pointer;
  padding: 8px 16px;
}

.divider {
  color: #868c8a;
}

.active-tab {
  border-radius: 90px;
  background-color: #2d2e2e;
  color: #e2f0e7;
  transition: transform 0.2s ease;
}

.active-tab:hover {
  transform: translateY(-1px);
}

.inactive-tab {
  color: #868c8a;
  transition: color 0.2s ease, transform 0.2s ease;
}

.inactive-tab:hover {
  color: #a8aea9;
  transform: translateY(-1px);
}

.test-case-input {
  background-color: #191c1b;
  color: #f8fcfa;
  border: none;
  border-radius: 0 12px 12px 0;
  padding: 12px;
  font-family: monospace;
  resize: none;
  outline: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.5;
  caret-color: #4fff4f;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5;
  font-family: monospace;
  font-size: 14px;
}

.running-indicator {
  font-size: 0.8em;
  color: #666;
  margin-left: 8px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}
