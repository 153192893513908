.questions-panel {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  max-width: 60%;
  height: 100vh;
  background-color: #1a1f1c;
  color: white;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  border-right: 1px solid #333;

  &.open {
    left: 0;
  }
}

.questions-header {
  padding: 20px;
  border-bottom: 1px solid #333;

  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    font-weight: 600;
  }
}

.search-container {
  padding: 16px;
}

.search-input {
  height: 41px !important;

  border: solid 1px #F8FCFA !important;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #1e1e1e;
  color: #868C8A !important;
}

.type-filter {
  padding: 8px 16px;
  color: #888;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chevron {
  font-size: 12px;
}

.questions-list {
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0 20px;
  margin-top: 8px;
}

.question-item {
  padding: 12px 16px;
  display: flex;
  // height: 41px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2d2d2d;
  border-radius: 10px;
}

.question-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.question-number {
  color: #888;
}

.question-type {
  color: #888;
  font-size: 14px;
  width: 40%;
  display: inline-block;
}

.add-button {
  background-color: transparent;
  color: #B4FF45;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.added-text {
  color: #888;
  font-size: 14px;
}

.pagination {
  // border: solid 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  border-top: 1px solid #333;

  .paginationNoBtns,
  .paginationNextPrevBtn {
    display: flex;
    gap: 6px;
  }

  .paginationNextPrevBtn {
    button {
      border: 1px solid #f8fafc;
    }
  }

  .paginationNoBtns {
    .active {
      color: #eff6ff;
      border: 1px solid #b4ff45;
    }
  }

  button {
    width: 32px;
    height: 32px;
    background-color: #191c1b;
    color: #9b9e9d;
    border-radius: 8px;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 40%;
    }
  }

  .done-button {
    margin-top: 10px;
    margin-left: auto;
    padding: 8px 20px;
    background-color: transparent;
    border: 1px solid #333;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    color: #131514;
    background-color: #B4FF45;
    width: 68px;
    height: 41px;
  }
}

.page-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid transparent;
  color: #888;
  cursor: pointer;
  border-radius: 4px;

  &.active {
    background-color: #1a1f1c;
    border-color: #333;
    color: white;
  }
}

.page-nav {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #333;
  color: #888;
  cursor: pointer;
  border-radius: 4px;
}



.column-id {
  // width: 5%;
  display: inline-block;
}

.question-title {
  width: 50%;
  display: inline-block;

}

.column-title {
  color: #9B9E9D;
  font-weight: 600;
}