.job-prev-company-box {
    display: grid;
    justify-items: center;
}

.job-prev-company-box img {
    border-radius: 50%;
}

.skill-chip {
    background-color: #DBEAFE;
    color: #3B82F6;
    padding: 6px 10px;
    border-radius: 6px;
}

hr.normal-divider {
    border: 0.5px solid var(--primary-border-color);
}

.preview-header-card {
    color: var(--primary-text-color);
    font-size: 14px;
    .company-name {
        font-size: 16px;
        font-weight: 600;
    }
}

.preview-content {
    max-height: calc(85vh - 350px);
    overflow: auto;
}

.preview-footer-card {
    border: 1px solid var(--primary-border-color);
    padding: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 10px rgba(92, 92, 92, 0.45);
    background: var(--secondary-background-color);
    .posted-by {
        font-size: 14px;
        font-weight: 400;
        color: var(--tertiary-text-color);
    }
}