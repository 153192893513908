.mask-group-icon {
  width: 1441px;
  height: 893px;
  position: absolute;
  margin: 0 !important;
  top: -4px;
  right: -1px;
  z-index: 1;
}
.container-icon {
  height: 1261.2px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
}
.homeframe-icon {
  height: 32px;
  width: 32px;
  position: relative;
  min-height: 32px;
}
.watch-desktop-app {
  align-self: stretch;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.watch-desktop-app-in-90-secs-wrapper {
  width: 272px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
}
.button,
.container-parent {
  margin: 0 !important;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.button {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px 23px;
  background-color: #3b82f6;
  height: 64px;
  top: 188.5px;
  left: 541.4px;
  border-radius: 90px;
  box-shadow: 0 4px 30px rgba(23, 25, 34, 0.23);
  box-sizing: border-box;
  gap: 5.2px;
  max-width: 1022.4000244140625px;
  z-index: 2;
}
.container-parent {
  width: 100%;
  height: 1261.2px;
  top: 1388.5px;
  left: 0.6px;
}
.group-icon {
  height: 30.8px;
  width: 31px;
  position: relative;
  min-height: 31px;
}
.jobify {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 64px;
  white-space: nowrap;
}
.global,
.link,
.spanml-2margin {
  display: flex;
  justify-content: flex-start;
}
.spanml-2margin {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 3.4px 0 8px;
}
.global,
.link {
  align-self: stretch;
  flex-direction: row;
}
.link {
  align-items: center;
  padding: 6px;
  gap: 4px;
}
.global {
  width: 458.7px;
  align-items: flex-start;
  max-width: 100%;
}
.faq,
.features,
.pricing {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 71px;
}
.faq,
.pricing {
  min-width: 58px;
}
.faq {
  min-width: 33px;
}
.ancestor-link,
.siblings-and-self {
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.ancestor-link {
  width: 260.6px;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  font-size: 18px;
  color: #1e293b;
  font-family: Roboto;
}
.siblings-and-self {
  width: 567.9px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 7.4px;
  box-sizing: border-box;
  max-width: 100%;
}
.login {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #1e293b;
  text-align: left;
  display: inline-block;
  min-width: 40px;
}
.svg-icon {
  width: 14px;
  height: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.svg-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
.link-wrapper,
.link1,
.svg-wrapper {
  display: flex;
  /* align-items: flex-start; */
}
.link1 {
  cursor: pointer;
  border: 0;
  padding: 5.9px 16px 6.1px 13.5px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #d0d1d6, 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.link-wrapper {
  width: 156px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 1.4px;
  box-sizing: border-box;
}
.divnav,
.nav-global {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.nav-global {
  height: 42.8px;
  flex: 1;
  align-items:center;
  justify-content: space-between;
  gap: 20px;
}
.divnav {
  width: 100%;
  margin: 0 !important;
  position: sticky;
  top: 0;
  left: -0.5px;
  border-bottom: 1px solid #e2e8f0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 32px;
  z-index: 99;
  text-align: left;
  font-size: 20px;
  color: #1e293b;
  font-family: Exo;
}
.coding-speaks-louder {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 72px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  max-width: 90% !important;
}
.coding-speaks-louder-than-your-wrapper {
  align-self: stretch;
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 503.1px;
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: 72px;
  color: #1e293b;
  font-family: "Work Sans";
  justify-items: center;
  margin-top: 150px;
}
.coding-speaks-louder-than-your-wrapper p{
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 40px;
  max-width: 60%;
}
.descendant-subtree-child {
  width: 2px;
  height: 36px;
  position: absolute;
  margin: 0 !important;
  top: 0;
}
.deserving-but-still {
  margin: 0;
  width: 448px;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.deserving-but-still-got-rejec-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 80px 0 79px;
}
.jobify1 {
  color: #3b82f6;
}
.worry-not-jobify-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 64px;
  font-weight: 700;
  font-family: "Work Sans";
  color: #1e293b;
}
.ancestor-subtree,
.descendant-subtree {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.descendant-subtree {
  width: 607px;
  flex-direction: column;
  justify-content:center;
  padding: 61px 0 0;
  position: relative;
  gap: 19px;
  align-items: center;
}
.ancestor-subtree {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px 66px 21px;
  flex-shrink: 0;
  text-align: center;
  font-size: 28px;
  color: #64748b;
  font-family: Inter;
}
.a34b5726eaad6b5541-causal20fo-icon {
  align-self: stretch;
  position: relative;
  border-radius: 24px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}
.jobify2 {
  font-weight: 800;
  margin-right: 5px;
}
.jobify-is-a-container1 {
  width: 100%;
}
.jobify-is-a-container {
  margin: 0;
  width: 913px;
  position: relative;
  font-size: inherit;
  line-height: 48px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
  font-family: inherit;
}
.branching-chain {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 0 21px;
  box-sizing: border-box;
  max-width: 100%;
}
.ancestor-and-descendant {
  width: 1154.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 94px;
  max-width: 100%;
}
.sibling-and-self {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 96px;
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: 32px;
  color: #64748b;
  font-family: Inter;
}
.ff41b3e9d8465bbeab4d9d-gridsv-icon {
  width: 576.9px;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  left: 4.5px;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.for-recruiters {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
.for-recruiters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 9px;
  font-size: 18px;
  color: #3b82f6;
}
.engineers,
.whats-our-runway {
  margin: 0;
}
.whats-our-runway-container {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.56px;
  line-height: 33.6px;
  font-weight: 500;
  font-family: inherit;
}
.tree-network {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 18px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 28px;
  color: #222b33;
}
.divh-4mask-group-icon {
  height: 16px;
  width: 16px;
  position: relative;
}
.save-time-and {
  position: relative;
  line-height: 24px;
}
.divflex,
.divm-1,
.divtext-md {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}
.divtext-md {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4.7px 0 0;
  max-width: calc(100% - 24px);
}
.divflex,
.divm-1 {
  flex-direction: row;
  max-width: 100%;
}
.divflex {
  align-items: center;
  padding: 3px 0;
  gap: 8px;
}
.divm-1 {
  border-radius: 8px;
  background-color: #fff6cc;
  border: 1px solid rgba(102, 83, 0, 0.1);
  align-items: flex-start;
  padding: 1px 12px 1px 8px;
}
.divh-4mask-group-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
}
.identify-the-most {
  position: relative;
  line-height: 24px;
}
.divtext-md1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 12.4px 0 0;
  box-sizing: border-box;
  min-width: 266px;
}
.complex-graph,
.divflex1,
.divm-11,
.divtext-md1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.divflex1 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 3px 0;
  box-sizing: border-box;
  gap: 8px;
}
.complex-graph,
.divm-11 {
  align-items: flex-start;
}
.divm-11 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff6cc;
  border: 1px solid rgba(102, 83, 0, 0.1);
  box-sizing: border-box;
  flex-direction: row;
  padding: 1px 12px 1px 8px;
}
.complex-graph {
  width: 455.4px;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  font-family: Roboto;
}
.divh-4mask-group-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
}
.get-a-better {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.divtext-md2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 11.2px 0 0;
  box-sizing: border-box;
  min-width: 301px;
  max-width: 100%;
}
.divflex2,
.divm-12 {
  flex-direction: row;
  flex-shrink: 0;
}
.divflex2 {
  width: 487px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
  box-sizing: border-box;
  gap: 8px;
  max-width: 107%;
}
.divm-12 {
  width: 465px;
  border-radius: 8px;
  background-color: #fff6cc;
  border: 1px solid rgba(102, 83, 0, 0.1);
  padding: 1px 0 1px 9px;
  max-width: 100%;
  font-family: Roboto;
}
.divm-12,
.divtextbox,
.mixed-cluster {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.divtextbox {
  height: 323.2px;
  flex: 1;
  flex-direction: column;
  padding: 30px 0 54.2px;
  gap: 11px;
  max-width: 500px;
  z-index: 1;
}
.mixed-cluster {
  align-self: stretch;
  flex-direction: row;
  padding: 0 43px;
  max-width: 100%;
}
.buildings-icon {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.company-details {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 107px;
}
.parallel-processing {
  flex: 0.9575;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.1px 8px 11.3px;
  box-sizing: border-box;
  gap: 6.5px;
  min-width: 98px;
  color: #1e293b;
}
.arrow-chevron-down-icon,
.briefcase-icon {
  height: 22.4px;
  width: 22.4px;
  position: relative;
}
.arrow-chevron-down-icon {
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.briefcase-icon {
  object-fit: cover;
}
.job-details {
  position: relative;
  font-size: 13.1px;
  font-weight: 600;
  font-family: Inter;
  color: #1e293b;
  text-align: left;
  display: inline-block;
  min-width: 70px;
}
.data-processor {
  cursor: pointer;
  border: 0;
  padding: 11.1px 26px 11.3px;
  background-color: transparent;
  flex: 0.7023;
  border-bottom: 1.9px solid #3b82f6;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
  min-width: 98px;
}
.data-processor:hover {
  background-color: rgba(84, 156, 255, 0.09);
}
.arrow-chevron-down-icon1 {
  object-fit: contain;
}
.arrow-chevron-down-icon1,
.iconsaxlinearusersearch {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.candidate-details {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 112px;
}
.data-filter {
  flex: 1;
  border-radius: 83.93px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.1px 5px 11.3px;
  box-sizing: border-box;
  gap: 6.5px;
  min-width: 98px;
}
.arrow-chevron-down-icon2 {
  object-fit: contain;
}
.arrow-chevron-down-icon2,
.iconsaxlinearmessagetext {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.job-description {
  height: 16px;
  flex: 1;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.data-transformer {
  flex: 0.9135;
  border-radius: 83.93px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.1px 11.1px 11.3px;
  box-sizing: border-box;
  gap: 7.5px;
  min-width: 98px;
}
.data-validator-icon,
.process-flow {
  align-self: stretch;
  overflow: hidden;
  flex-shrink: 0;
}
.process-flow {
  box-shadow: 0 3.7px 37.3px rgba(9, 19, 21, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 14px;
  gap: 7.5px;
}
.data-validator-icon {
  height: 0.9px;
  position: relative;
  max-width: 100%;
  z-index: 1;
}
.control-flow {
  align-self: stretch;
  height: 44.8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.job-details1,
.job-title {
  position: relative;
  font-weight: 600;
}
.job-details1 {
  display: inline-block;
  min-width: 79px;
  flex-shrink: 0;
}
.job-title {
  align-self: stretch;
}
.ux-designer,
.vuesaxlinearuser-square-icon {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
}
.ux-designer {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 13.1px;
  background-color: transparent;
  height: 18.6px;
  flex: 1;
  color: #1e293b;
  text-align: left;
  display: inline-block;
  min-width: 196px;
  max-width: 100%;
  padding: 0;
}
.error-control,
.error-input {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 7.5px;
  max-width: 100%;
}
.error-control {
  align-self: stretch;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #3b82f6;
  flex-direction: row;
  align-items: center;
  padding: 14.9px 9px 15px 14.9px;
}
.error-input {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.1px;
  min-width: 229px;
}
.experience-required {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon1 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
  min-height: 22px;
}
.na {
  flex: 1;
  position: relative;
}
.arrow-chevron-down-icon3 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.error-analysis {
  align-self: stretch;
  height: 48.5px;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13.1px 9px 13px 14.9px;
  gap: 7.5px;
  color: #94a3b8;
}
.error-checkpoint,
.error-output,
.error-transformer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.error-checkpoint {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.1px;
  box-sizing: border-box;
  gap: 7.5px;
  min-width: 229px;
}
.error-output,
.error-transformer {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 11.2px;
  flex-shrink: 0;
  font-size: 13.1px;
  color: #64748b;
}
.error-transformer {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  font-size: 14.9px;
  color: #1e293b;
}
.job-type {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon2 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
  min-height: 22px;
}
.full-time {
  flex: 1;
  position: relative;
}
.arrow-chevron-down-icon4 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.data-aggregator,
.string-collector {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 7.5px;
}
.string-collector {
  align-self: stretch;
  height: 48.5px;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  flex-direction: row;
  align-items: center;
  padding: 13.1px 9px 13px 14.9px;
  color: #1e293b;
}
.data-aggregator {
  width: 352.5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.1px;
  max-width: 100%;
}
.compensationfrom {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.output-combiner,
.vuesaxlinearuser-square-icon3 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
}
.output-combiner {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 13.1px;
  background-color: transparent;
  height: 18.6px;
  flex: 1;
  color: #94a3b8;
  text-align: left;
  display: inline-block;
  min-width: 87px;
  white-space: nowrap;
  padding: 0;
}
.comparison-cluster,
.input-divider {
  display: flex;
  justify-content: flex-start;
  gap: 7.5px;
}
.input-divider {
  align-self: stretch;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  flex-direction: row;
  align-items: center;
  padding: 15px 9px 14.9px 14.9px;
}
.comparison-cluster {
  width: 170.6px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.1px;
  box-sizing: border-box;
  min-width: 111px;
}
.compensationto {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon4 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
}
.data-transformer1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  white-space: nowrap;
}
.control-flow1,
.function-tree {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 7.5px;
}
.control-flow1 {
  align-self: stretch;
  height: 48.5px;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  flex-direction: row;
  align-items: center;
  padding: 15px 9px 14.9px 15px;
  color: #94a3b8;
}
.function-tree {
  width: 170.6px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.1px;
  min-width: 111px;
}
.error-handling,
.error-prevention {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.error-prevention {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.1px 0 0;
  box-sizing: border-box;
  gap: 11.2px;
}
.error-handling {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
}
.contract-type {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.per-hour,
.vuesaxlinearuser-square-icon5 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
}
.per-hour {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 13.1px;
  background-color: transparent;
  height: 18.6px;
  flex: 1;
  color: #94a3b8;
  text-align: left;
  display: inline-block;
  min-width: 196px;
  max-width: 100%;
  padding: 0;
}
.event-trigger,
.signal-emitter {
  display: flex;
  justify-content: flex-start;
  gap: 7.5px;
  max-width: 100%;
}
.signal-emitter {
  align-self: stretch;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 14.9px 9px 15px 14.9px;
}
.event-trigger {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 229px;
}
.additional-compensation {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon6 {
  height: 22.4px;
  width: 22.4px;
  position: relative;
  display: none;
}
.tips-bonuses {
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: calc(100% - 0px);
}
.matrix-transformer {
  align-self: stretch;
  border-radius: 7.46px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 14.9px 9px 15px 14.9px;
  gap: 7.5px;
  white-space: nowrap;
  color: #94a3b8;
}
.additional-compensation-parent,
.error-handler,
.matrix-transformer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.additional-compensation-parent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.5px;
  min-width: 229px;
}
.error-handler {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 11.2px;
}
.error-filter,
.error-handler-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.error-handler-wrapper {
  align-self: stretch;
  flex-shrink: 0;
}
.error-filter {
  flex: 1;
  gap: 22.4px;
}
.error-filter-wrapper {
  align-self: stretch;
  border-radius: 14.92px;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px 14.8px 14.9px;
  max-width: 100%;
}
.error-filter-wrapper,
.input-connectors,
.output-connectors {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
}
.output-connectors {
  width: 746px;
  border-radius: 14.92px;
  background-color: #fff;
  box-shadow: 0 3.7px 18.65px rgba(9, 19, 21, 0.08);
  border: 0.9px solid #e2e8f0;
  flex-direction: column;
  align-items: center;
  padding: 7.5px 0 0.1px;
  gap: 3.7px;
  opacity: 0.68;
  max-width: 144%;
}
.input-connectors {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 24px;
  max-width: 100%;
}
.design-system- {
  position: relative;
  font-weight: 600;
}
.iconbox {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon {
  width: 16px;
  height: 16px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.map-icon {
  height: 24px;
  width: 24px;
  position: relative;
  min-height: 24px;
}
.placeholder {
  position: relative;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 111px;
}
.homeframe-wrapper,
.map-parent,
.placeholder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.homeframe-wrapper,
.map-parent {
  flex-direction: row;
  align-items: center;
}
.homeframe-wrapper {
  padding: 3.5px 0;
}
.map-parent {
  flex: 1;
  gap: 4px;
  min-width: 90px;
}
.homeframe-child,
.user-icon {
  height: 12.5px;
  width: 1px;
  position: relative;
}
.user-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.placeholder1 {
  position: relative;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 117px;
}
.serializers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.serializers-wrapper,
.user-parent {
  flex-direction: row;
  align-items: center;
}
.serializers-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 3.5px 0;
}
.user-parent {
  flex: 1;
  gap: 4px;
  min-width: 94px;
}
.design-system-ux-parent,
.homeframe-group,
.user-parent {
  display: flex;
  justify-content: flex-start;
}
.homeframe-group {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #64748b;
}
.design-system-ux-parent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 200px;
}
.link2,
.preview-job {
  display: flex;
  align-items: center;
}
.preview-job {
  width: 83px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  color: #1e293b;
  text-align: center;
  justify-content: center;
  min-width: 83px;
}
.link2 {
  cursor: pointer;
  border: 1px solid #e2e8f0;
  padding: 12px 15px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  white-space: nowrap;
}
.link2:hover {
  background-color: rgba(201, 207, 214, 0.09);
  border: 1px solid #c9cfd6;
  box-sizing: border-box;
}
.next {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 32px;
}
.formatters,
.homeframe-parent,
.link3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.link3 {
  cursor: pointer;
  border: 0;
  padding: 12px 16px;
  background-color: #3b82f6;
  width: 64px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  box-sizing: border-box;
}
.link3:hover,
.link6:hover {
  background-color: #549cff;
}
.formatters,
.homeframe-parent {
  flex-direction: row;
  gap: 12px;
}
.homeframe-parent {
  position: relative;
  align-self: stretch;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 40px rgba(9, 19, 21, 0.08);
  border: 1px solid #e2e8f0;
  padding: 16px;
  flex-shrink: 0;
  z-index: 1;
  margin-top: -113.6px;
  font-size: 20px;
  color: #1e293b;
}
.connection-points,
.divw-node-1924c46e-32c9-1c4b,
.ff41b3e9d8465bbeab4d9d-gridsv {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.connection-points {
  align-self: stretch;
  border-radius: 0 0 16px 0;
  overflow: hidden;
  flex-direction: column;
  padding: 0 20px 22px 22px;
  max-width: 100%;
  z-index: 2;
  font-size: 13.1px;
  color: #64748b;
}
.divw-node-1924c46e-32c9-1c4b,
.ff41b3e9d8465bbeab4d9d-gridsv {
  flex: 1;
}
.ff41b3e9d8465bbeab4d9d-gridsv {
  flex-direction: column;
  padding: 0 0 0.2px;
  position: relative;
  gap: 30.8px;
  opacity: 0.73;
  max-width: 588px;
}
.divw-node-1924c46e-32c9-1c4b {
  border-radius: 16px;
  background: linear-gradient(0deg, #f8faff 10%, #fff 95%);
  box-shadow: 0 4px 40px rgba(9, 19, 21, 0.08);
  border: 1px solid #e2e8f0;
  flex-direction: row;
  padding: 1px;
  min-width: 383px;
  max-width: 100%;
  flex-shrink: 0;
  z-index: 1;
}
.ff41b3e9d8465bbeab4d9d-gridsv-icon1 {
  width: 576.9px;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  left: 4.5px;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.for-job-applicants {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 1;
}
.contractors-last-quarter,
.how-did-we {
  margin: 0;
}
.how-did-we-container {
  margin: 0;
  position: relative;
  font-size: 28px;
  letter-spacing: -0.56px;
  line-height: 33.6px;
  font-weight: 500;
  font-family: inherit;
  color: #222b33;
  z-index: 1;
}
.for-job-applicants-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  flex-shrink: 0;
}
.divh-4mask-group-icon3 {
  height: 16px;
  width: 16px;
  position: relative;
}
.have-the-opportunity {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.divflex3,
.divm-13,
.divtext-md3 {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.divtext-md3 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4.7px 0 0;
  min-width: 100%;

}
.divflex3,
.divm-13 {
  flex-direction: row;
}
.divflex3 {
  flex: 1;
  align-items: center;
  padding: 3px 0;
  gap: 8px;
}
.divm-13 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff6cc;
  border: 1px solid rgba(102, 83, 0, 0.1);
  align-items: flex-start;
  padding: 1px 12px 1px 8px;
  z-index: 1;
}
.divh-4mask-group-icon4 {
  height: 16px;
  width: 16px;
  position: relative;
}
.divflex4,
.divm-14,
.divtext-md4 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.divtext-md4 {
  width: calc(100% - 16px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-size: 16px;
  color: #665300;
  min-width: 241px;
}
.divflex4,
.divm-14 {
  box-sizing: border-box;
  flex-direction: row;
}
.divflex4 {
  flex: 1;
  align-items: center;
  padding: 3px 0;
  gap: 8px;
}
.divm-14 {
  width: 417px;
  border-radius: 8px;
  background-color: #fff6cc;
  border: 1px solid rgba(102, 83, 0, 0.1);
  align-items: flex-start;
  padding: 1px 12px 1px 8px;
  z-index: 1;
}
.divh-4mask-group-icon5 {
  height: 16px;
  width: 16px;
  position: relative;
}
.have-a-record {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.divtext-md5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4.7px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.divflex5,
.divm-15 {
  box-sizing: border-box;
  flex-direction: row;
}
.divflex5 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
  gap: 8px;
  max-width: 100%;
}
.divm-15 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff6cc;
  border: 1px solid rgba(102, 83, 0, 0.1);
  padding: 1px 12px 1px 8px;
  z-index: 1;
}
.divm-1-parent,
.divm-15,
.ff41b3e9d8465bbeab4d9d-gridsv-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.divm-1-parent {
  flex: 1;
  flex-direction: column;
  gap: 10px;
}
.ff41b3e9d8465bbeab4d9d-gridsv-inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 13px 0 3px;
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: 16px;
  color: #665300;
  font-family: Roboto;
}
.upload-resume {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 126px;
}
.an-updated-resume {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  color: #64748b;
}
.upload-resume-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 3.4px;
  min-width: 209px;
  max-width: 100%;
}
.span {
  color: #1e293b;
}
.step-33 {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 53px;
  white-space: nowrap;
}
.homeframe-inner,
.homeframe-item,
.value-extractor {
  height: 6.9px;
  width: 15.2px;
  position: relative;
  border-radius: 4.29px;
  background-color: #3b82f6;
}
.homeframe-inner {
  background-color: #e2e8f0;
}
.value-extractor-parent {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  gap: 3.4px;
}
.key-value-pair,
.step-33-parent,
.value-extractor-parent {
  display: flex;
  align-items: flex-start;
}
.step-33-parent {
  width: 52.3px;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  gap: 6.9px;
  text-align: right;
  font-size: 12px;
  color: #64748b;
}
.key-value-pair {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10.3px;
  max-width: 100%;
  flex-shrink: 0;
  font-size: 17.2px;
}
.what-is-your {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.job-title1,
.vuesaxlinearuser-square-icon7 {
  height: 20.6px;
  width: 20.6px;
  position: relative;
  display: none;
}
.job-title1 {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 12px;
  background-color: transparent;
  height: 17.2px;
  flex: 1;
  color: #94a3b8;
  text-align: left;
  display: inline-block;
  min-width: 98px;
  padding: 0;
}
.vuesaxlinearuser-square-parent {
  flex: 0.8694;
  border-radius: 6.86px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13.7px 9px 13.7px 13.7px;
  gap: 6.9px;
  min-width: 122px;
}
.vuesaxlinearuser-square-icon8 {
  height: 20.6px;
  width: 20.6px;
  position: relative;
  display: none;
}
.company {
  flex: 1;
  position: relative;
}
.vuesaxlinearuser-square-group {
  align-self: stretch;
  border-radius: 6.86px;
  background-color: #fff;
  border: 0.9px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13.7px 9px 13.7px 13.7px;
  gap: 6.9px;
}
.homeframe-wrapper1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 122px;
}
.homeframe-div,
.what-is-your-current-job-title-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.3px;
}
.homeframe-div {
  flex-direction: row;
  padding: 0;
  color: #94a3b8;
}
.what-is-your-current-job-title-parent {
  flex-direction: column;
  flex-shrink: 0;
}
.if-you-dont,
.upload-your-updated {
  align-self: stretch;
  position: relative;
}
.upload-your-updated {
  font-weight: 600;
}
.if-you-dont {
  font-size: 10.3px;
  color: #64748b;
}
.upload-your-updated-resume-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.9px;
}
.image-17-icon,
.vuesaxlinearuser-square-icon9 {
  height: 20.6px;
  width: 20.6px;
  position: relative;
  min-height: 21px;
}
.vuesaxlinearuser-square-icon9 {
  display: none;
}
.image-17-icon {
  object-fit: cover;
}
.anuragsingh-resumepdf {
  flex: 1;
  position: relative;
  display: inline-block;
  min-width: 113px;
}
.repeat {
  margin: 0;
  height: 20.6px;
  width: 20.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 21px;
}
.homeframe-parent1,
.vuesaxlinearuser-square-container {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.vuesaxlinearuser-square-container {
  border-radius: 6.86px;
  background-color: #f8fafc;
  border: 0.9px solid #e2e8f0;
  flex-direction: row;
  align-items: center;
  padding: 13.7px 9px 13.7px 13.7px;
  gap: 3.4px;
  color: #64748b;
}
.homeframe-parent1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10.3px;
  flex-shrink: 0;
}
.arrow-chevron-down-icon5 {
  height: 20.6px;
  width: 20.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.finish,
.link4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.link4 {
  height: 44.6px;
  width: 58.3px;
  border-radius: 6.86px;
  border: 0.9px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: 12px 18px;
}
.finish {
  width: 42px;
  position: relative;
  line-height: 25px;
  font-weight: 600;
  min-width: 42px;
}
.link-parent,
.link5 {
  display: flex;
  flex-direction: row;
}
.link5 {
  flex: 1;
  border-radius: 6.86px;
  background-color: #3b82f6;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 9.8px 20px;
  box-sizing: border-box;
  min-width: 42px;
}
.link-parent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.3px;
  text-align: center;
  font-size: 15.4px;
  color: #fff;
  font-family: Roboto;
}
.key-value-pair-parent {
  flex: 1;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: 17.2px;
}
.error-handler-inner,
.homeframe-container,
.key-value-pair-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-container {
  align-self: stretch;
  border-radius: 13.72px;
  background-color: #fff;
  box-shadow: 0 3.4px 17.15px rgba(9, 19, 21, 0.08);
  flex-direction: row;
  padding: 13.7px 14px 13.8px 13.7px;
  box-sizing: border-box;
}
.error-handler-inner {
  position: absolute;
  top: 21px;
  left: 2px;
  width: 411.6px;
  flex-direction: column;
  opacity: 0.7;
}
.add-your-skills {
  position: relative;
  font-weight: 600;
}
.hiwe-just-need {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  color: #64748b;
}
.add-your-skills-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 244px;
  max-width: 100%;
}
.span1 {
  color: #1e293b;
}
.step-13 {
  width: 59px;
  position: relative;
  display: inline-block;
}
.homeframe-child1,
.homeframe-child2,
.rectangle-div {
  height: 8px;
  width: 17.7px;
  position: relative;
  border-radius: 5px;
  background-color: #3b82f6;
}
.homeframe-child1,
.homeframe-child2 {
  background-color: #e2e8f0;
}
.rectangle-parent {
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
}
.homeframe-parent3,
.rectangle-parent,
.step-13-parent {
  display: flex;
  align-items: flex-start;
}
.step-13-parent {
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  text-align: right;
  font-size: 14px;
  color: #64748b;
}
.homeframe-parent3 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  font-size: 20px;
}
.are-you-a {
  position: relative;
  font-weight: 600;
}
.iconbox1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot1 {
  height: 8px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.output-formatter {
  width: calc(100% - 28.7px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 17px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 14px;
  color: #020617;
  min-width: 257px;
  max-width: 100%;
}
.checked {
  position: absolute;
  top: calc(50% - 10px);
  left: 26.7px;
  letter-spacing: -0.03em;
  line-height: 20px;
  display: none;
}
.homeframe-child3 {
  border-radius: 13px;
  background-color: #3b82f6;
}
.checkboxchecked-inner,
.homeframe-child3,
.path-5-copy-10 {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 100%;
  height: 100%;
}
.path-5-copy-10 {
  top: calc(50% - 2.5px);
  left: 6.7px;
  width: 6.7px;
  height: 5px;
  z-index: 1;
}
.checkboxchecked {
  height: 20px;
  width: 20px;
  position: relative;
}
.dropdown-label-wrapper {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 11px;
  gap: 8px;
  max-width: 100%;
}
.iconbox2 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot2 {
  height: 8px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.dropdown-label-wrapper-child {
  width: calc(100% - 42px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 17px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 14px;
  color: #020617;
  min-width: 257px;
  max-width: 100%;
}
.checked1 {
  position: absolute;
  top: calc(50% - 10px);
  left: 26.7px;
  letter-spacing: -0.03em;
  line-height: 20px;
  display: none;
}
.homeframe-child4 {
  border-radius: 15px;
  border: 1.5px solid #94a3b8;
  box-sizing: border-box;
}
.checkboxchecked-child,
.homeframe-child4 {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 100%;
  height: 100%;
}
.checkboxchecked1 {
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: 90px;
}
.dropdown-label-wrapper1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 11px;
  gap: 8px;
  max-width: 100%;
}
.are-you-a-working-professional-parent,
.layout-composer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.layout-composer {
  gap: 8px;
  font-size: 13.3px;
  color: #abb7bd;
  font-family: "Messina Sans";
}
.are-you-a-working-professional-parent {
  gap: 12px;
}
.how-many-years {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon10 {
  height: 24px;
  width: 24px;
  position: relative;
  display: none;
}
.eg-3 {
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.homeframe-wrapper2,
.vuesaxlinearuser-square-parent1 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.vuesaxlinearuser-square-parent1 {
  width: 448px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  flex-direction: row;
  align-items: center;
  padding: 16px 11px 16px 15px;
  gap: 8px;
  white-space: nowrap;
  max-width: 117%;
  flex-shrink: 0;
}
.homeframe-wrapper2 {
  width: 383px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  max-width: 100%;
}
.vuesaxlinearuser-square-icon11,
.years {
  height: 24px;
  width: 24px;
  position: relative;
  display: none;
}
.years {
  width: 37px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 17px;
  color: #94a3b8;
  text-align: left;
  display: inline-block;
  padding: 0;
}
.vuesaxlinearuser-square-parent2 {
  align-self: stretch;
  border-radius: 0 8px 8px 0;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 17.5px 11px 17.5px 15px;
  gap: 8px;
}
.homeframe-wrapper3 {
  width: 65px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.homeframe-parent4,
.how-many-years-of-work-experie-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent4 {
  flex-direction: row;
  row-gap: 20px;
  color: #94a3b8;
}
.how-many-years-of-work-experie-parent {
  flex-direction: column;
  gap: 12px;
}
.select-your-current {
  align-self: stretch;
  height: 17px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon12 {
  height: 24px;
  width: 24px;
  position: relative;
  display: none;
}
.eg-31 {
  height: 20px;
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.homeframe-wrapper4,
.vuesaxlinearuser-square-parent3 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.vuesaxlinearuser-square-parent3 {
  width: 448px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  flex-direction: row;
  align-items: center;
  padding: 16px 11px 16px 15px;
  gap: 8px;
  white-space: nowrap;
  max-width: 109%;
  flex-shrink: 0;
}
.homeframe-wrapper4 {
  width: 412px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  max-width: 100%;
}
.arrow-chevron-down-icon6 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.homeframe-parent5,
.select-your-current-role-parent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent5 {
  flex-direction: row;
  align-items: center;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  row-gap: 20px;
  color: #94a3b8;
}
.select-your-current-role-parent {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.link6,
.next1 {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.next1 {
  width: 38px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
}
.link6 {
  cursor: pointer;
  border: 0;
  padding: 12px 20px;
  background-color: #3b82f6;
  height: 52px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
}
.error-handler-child,
.homeframe-parent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent2 {
  flex: 1;
  flex-direction: column;
  gap: 20px;
}
.error-handler-child {
  position: absolute;
  top: 70px;
  left: 121px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(9, 19, 21, 0.08);
  width: 480px;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  z-index: 1;
  font-size: 14px;
}
.error-handler1 {
  height: 372px;
  width: 548px;
  position: relative;
  border-radius: 0 0 16px 0;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 109%;
  z-index: 2;
}
.divw-node-1924c46e-32c9-1c4b1,
.ff41b3e9d8465bbeab4d9d-gridsv1,
.result-combiner {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.result-combiner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 13px 0 8px;
  max-width: 100%;
  font-size: 12px;
  color: #1e293b;
}
.divw-node-1924c46e-32c9-1c4b1,
.ff41b3e9d8465bbeab4d9d-gridsv1 {
  flex: 1;
}
.ff41b3e9d8465bbeab4d9d-gridsv1 {
  height: 755.2px;
  flex-direction: column;
  padding: 56px 31px 398.2px;
  position: relative;
  gap: 28px;
  opacity: 0.73;
  max-width: 588px;
}
.divw-node-1924c46e-32c9-1c4b1 {
  border-radius: 16px;
  background: linear-gradient(0deg, #f8faff 10%, #fff 95%);
  box-shadow: 0 4px 40px rgba(9, 19, 21, 0.08);
  border: 1px solid #e2e8f0;
  flex-direction: row;
  padding: 1px;
  min-width: 383px;
  max-width: 100%;
  flex-shrink: 0;
  z-index: 1;
  font-size: 18px;
  color: #3b82f6;
}
.connected-cluster,
.connected-cluster-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.connected-cluster {
  width: 1216px;
  flex-wrap: wrap;
  gap: 40px;
}
.connected-cluster-wrapper {
  align-self: stretch;
  padding: 0 20px 137.8px;
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: 16px;
  color: #665300;
  font-family: Inter;
}
.the-details,
.to-desktop-handles {
  margin: 0;
}
.to-desktop-handles-container {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 72px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 1280px;
}
.in-addition-to {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 672px;
}
.in-addition-to-the-benefits-li-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 7.5px;
  box-sizing: border-box;
  font-size: 20px;
  color: #1e293b;
  font-family: Inter;
}
.homeframe-wrapper5,
.in-addition-to-the-benefits-li-wrapper,
.to-desktop-handles-the-details-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.to-desktop-handles-the-details-parent {
  flex: 1;
  flex-direction: column;
  gap: 32px;
}
.homeframe-wrapper5 {
  width: 1184.5px;
  flex-direction: row;
  padding: 0 79px 60px 79.5px;
  box-sizing: border-box;
  font-size: 64px;
  font-family: "Work Sans";
}
.monitored-and-recorded {
  flex: 1;
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.monitored-and-recorded-coding-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 18px 0 8px;
}
.image-18-icon {
  height: 46.3px;
  width: 46.3px;
  position: relative;
  border-radius: 29.79px;
  object-fit: cover;
  min-height: 46px;
}
.design-system-1 {
  position: relative;
  font-size: 15.4px;
  font-weight: 600;
}
.iconbox3 {
  width: 18.5px;
  height: 18.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon3 {
  width: 12.4px;
  height: 12.4px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot3 {
  width: 6.2px;
  height: 6.2px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.jupiter-inc {
  position: relative;
  display: inline-block;
  min-width: 54px;
}
.logic-gate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.merger-icon {
  height: 9.7px;
  width: 0.8px;
  position: relative;
}
.div {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 14px;
}
.link7 {
  width: 26.4px;
  border-radius: 3.09px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3.1px 6.2px;
  box-sizing: border-box;
}
.remote {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 40px;
}
.comparator,
.link8,
.output-aggregator {
  display: flex;
  justify-content: flex-start;
}
.link8 {
  flex: 1;
  border-radius: 3.09px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 3.1px 6.2px;
}
.comparator,
.output-aggregator {
  flex-direction: row;
}
.comparator {
  flex: 1;
  align-items: flex-start;
  gap: 4.6px;
  text-align: center;
  color: #64748b;
}
.output-aggregator {
  align-self: stretch;
  height: 19.2px;
  align-items: center;
  gap: 9.3px;
  color: #3b82f6;
}
.fulltime {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 40px;
}
.link9 {
  width: 52.4px;
  border-radius: 3.09px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3.1px 6.2px;
  box-sizing: border-box;
}
.bangalore {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 52px;
}
.homeframe-wrapper8,
.link-group,
.link10 {
  display: flex;
  justify-content: flex-start;
}
.link10 {
  flex: 1;
  border-radius: 3.09px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 3.1px 6.3px 3.1px 6.1px;
}
.homeframe-wrapper8,
.link-group {
  flex-direction: row;
}
.link-group {
  flex: 1;
  align-items: flex-start;
  gap: 4.6px;
}
.homeframe-wrapper8 {
  width: 121.4px;
  align-items: center;
  text-align: center;
  color: #64748b;
}
.design-system-ux-group,
.image-18-parent {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.design-system-ux-group {
  height: 46.3px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 3.1px;
  min-width: 101px;
}
.image-18-parent {
  flex-direction: row;
  justify-content: center;
  gap: 9.3px;
  min-width: 137px;
  flex-shrink: 0;
}
.take-test {
  align-self: stretch;
  position: relative;
  font-size: 10.8px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 51px;
}
.link-container,
.link11 {
  display: flex;
  align-items: center;
}
.link11 {
  flex: 1;
  border-radius: 6.18px;
  background-color: #22c55e;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding: 9.2px 14px 9.3px 14.2px;
  white-space: nowrap;
}
.link-container {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.link-child {
  height: 12.5px;
  width: 12.5px;
  position: relative;
}
.record {
  flex: 1;
  position: relative;
  font-size: 10.8px;
  font-weight: 600;
  font-family: Inter;
  color: #ef4444;
  text-align: center;
  display: inline-block;
  min-width: 38px;
}
.link-homeframe,
.link12 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.link12 {
  flex: 1;
  border-radius: 6.18px;
  justify-content: center;
  padding: 9.3px 12.3px 9.2px 12.4px;
  gap: 4px;
}
.link-homeframe {
  cursor: pointer;
  border: 0.8px solid #e2e8f0;
  padding: 0;
  background-color: transparent;
  height: 31.5px;
  border-radius: 7px;
  box-sizing: border-box;
  flex-shrink: 0;
  justify-content: flex-start;
}
.divgroup,
.divrelative,
.homeframe-parent7,
.homeframe-parent8 {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}
.homeframe-parent8 {
  width: 79.2px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 0.1px;
  gap: 4px;
  flex-shrink: 0;
}
.divgroup,
.divrelative,
.homeframe-parent7 {
  max-width: 100%;
}
.homeframe-parent7 {
  width: 324.7px;
  border-radius: 12.36px;
  background-color: #fff;
  box-shadow: 0 3.1px 15.45px rgba(9, 19, 21, 0.08);
  flex-direction: row;
  justify-content: center;
  padding: 12.4px 12px 12.3px 12.4px;
  gap: 9.3px;
  z-index: 1;
  text-align: left;
  font-size: 10.8px;
  font-family: Inter;
}
.divgroup,
.divrelative {
  justify-content: flex-start;
}
.divrelative {
  flex: 1;
  border-radius: 15px;
  flex-direction: column;
  padding: 30px 38px 19.2px;
  gap: 8px;
}
.divgroup {
  align-self: stretch;
  border-radius: 16px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  flex-direction: row;
  padding: 1px 0 0;
}
.focus-on-tech {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.focus-on-tech-job-applicants-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 21px 0 12.9px;
}
.are-always-up,
.browser-performance-improveme,
.security-patches-and,
.to-desktop-ensures {
  margin: 0;
}
.to-desktop-ensures-container {
  align-self: stretch;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-family: Inter;
  text-align: center;
}
.homeframe-parent9 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
}
.homeframe-child5 {
  height: 63px;
  width: 63px;
  position: relative;
  min-height: 63px;
  z-index: 1;
}
.arrows-join-icon {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
}
.arrows-join-wrapper {
  height: 43.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 19.5px 0 0;
  box-sizing: border-box;
}
.wrapper-group-146407-child {
  height: 100%;
  width: 100%;
  min-height: 63px;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 4px;
  transform: scale(1.635);
}
.wrapper-group-146407 {
  height: 63px;
  width: 63px;
  position: relative;
  min-height: 63px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divrelative-inner,
.homeframe-parent10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.homeframe-parent10 {
  justify-content: flex-start;
  gap: 15px;
}
.divrelative-inner {
  height: 63px;
  justify-content: center;
}
.divrelative1 {
  align-self: stretch;
  border-radius: 15px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 33px 32px 47px;
  gap: 30px;
  text-align: left;
}
.divgroup-parent,
.homeframe-wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}
.divgroup-parent {
  align-self: stretch;
  justify-content: flex-start;
  gap: 27px;
}
.homeframe-wrapper7 {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 1px;
  box-sizing: border-box;
  min-width: 308px;
  text-align: center;
}
.streamlined-interview-process {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.jobify-is-helpful {
  flex: 1;
  position: relative;
  line-height: 22px;
  display: inline-block;
  max-width: 100%;
}
.jobify-is-helpful-in-streamlin-wrapper,
.streamlined-interview-process-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.jobify-is-helpful-in-streamlin-wrapper {
  flex-direction: row;
  padding: 0 1px 0 9.9px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  font-family: Inter;
}
.streamlined-interview-process-parent {
  flex-direction: column;
  gap: 17px;
}
.step-1 {
  position: relative;
  font-size: 12px;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 36px;
}
.divflex6,
.divm-16 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
}
.divm-16 {
  cursor: pointer;
  border: 0;
  padding: 6px 10px;
  background-color: #3b82f6;
  flex: 1;
  border-radius: 90px;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 38px;
}
.chevron-icon {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.step-11 {
  position: relative;
  font-size: 12px;
  font-family: Inter;
  color: #64748b;
  text-align: left;
  display: inline-block;
  min-width: 36px;
}
.divflex7,
.divm-17 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
}
.divm-17 {
  cursor: pointer;
  border: 0;
  padding: 6px 10px;
  background-color: #e2e8f0;
  flex: 1;
  border-radius: 90px;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 38px;
}
.chevron-icon1 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.step-3 {
  position: relative;
  font-size: 12px;
  font-family: Inter;
  color: #64748b;
  text-align: left;
  display: inline-block;
  min-width: 38px;
}
.divflex8,
.divm-18 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
}
.divm-18 {
  cursor: pointer;
  border: 0;
  padding: 6px 10px;
  background-color: #e2e8f0;
  flex: 1;
  border-radius: 90px;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 38px;
}
.chevron-icon2 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.step-4 {
  position: relative;
  font-size: 12px;
  font-family: Inter;
  color: #64748b;
  text-align: left;
  display: inline-block;
  min-width: 38px;
}
.divflex9,
.divm-1-group,
.divm-19 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.divflex9 {
  align-items: center;
  padding: 3px 0;
}
.divm-1-group,
.divm-19 {
  flex: 1;
}
.divm-19 {
  cursor: pointer;
  border: 0;
  padding: 6px 10px;
  background-color: #e2e8f0;
  border-radius: 90px;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 38px;
}
.divm-1-group {
  align-items: center;
  gap: 8px;
}
.divrelative-child {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 5.7px 0 15px;
}
.divrelative-wrapper,
.divrelative2 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.divrelative2 {
  flex: 1;
  border-radius: 15px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: 33px 41px 41px 33px;
  gap: 46px;
}
.divrelative-wrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1px;
}
.customizable-look-and {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.divfont-display {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 19px 0 6px;
}
.look-and-feel3xpng-icon {
  width: 78.68%;
  height: 105px;
  position: absolute;
  margin: 0 !important;
  right: 10.43%;
  bottom: -2px;
  left: 10.89%;
  max-width: 320px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  max-height: 105px;
}
.divrelative3 {
  align-self: stretch;
  flex: 1;
  border-radius: 15px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 41.5px 44.4px 0 44.3px;
  position: relative;
}
.divgroup1,
.homeframe-parent11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divgroup1 {
  align-self: stretch;
  height: 216px;
  border-radius: 16px;
  background-color: #f1f5f9;
  padding: 1px;
  box-sizing: border-box;
}
.homeframe-parent11 {
  flex: 1;
  gap: 25px;
  min-width: 308px;
  max-width: 100%;
}
.light-dark-themes {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.light-dark-themes-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 57px 0 56.5px;
}
.my-account {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.boolean-operator,
.data-aggregator1 {
  display: flex;
  justify-content: flex-start;
}
.boolean-operator {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.data-aggregator1 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  font-size: 13.1px;
}
.avatarssingle-icon {
  height: 23.4px;
  width: 23.4px;
  border-radius: 58.4px;
  object-fit: cover;
}
.aryan-singh,
.candidate-profile {
  position: relative;
  display: inline-block;
}
.aryan-singh {
  width: 61px;
  font-weight: 600;
  min-width: 61px;
}
.candidate-profile {
  min-width: 72px;
}
.divflex10 {
  align-items: center;
  padding: 2.2px 0;
}
.aryan-singh-parent,
.divflex10,
.divm-110 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.divm-110 {
  flex: 1;
  border-radius: 65.69px;
  background-color: #fff6cc;
  border: 0.7px solid rgba(102, 83, 0, 0.1);
  align-items: flex-start;
  padding: 1.4px 4px 1.5px 5.9px;
  text-align: left;
  font-size: 8.8px;
  color: #665300;
}
.aryan-singh-parent {
  align-self: stretch;
  align-items: center;
  gap: 5.8px;
}
.singhc102gmailcom {
  width: 108px;
  position: relative;
  color: #475569;
  display: inline-block;
  min-width: 108px;
  white-space: nowrap;
}
.homeframe-parent12 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2.9px;
  min-width: 97px;
}
.avatarssingle-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 104px 0 0;
  gap: 5.8px;
  text-align: right;
  font-size: 10.2px;
}
.iconbox4 {
  height: 17.5px;
  width: 17.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon4 {
  height: 11.7px;
  width: 11.7px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot4 {
  height: 5.8px;
  width: 5.8px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.sun-icon {
  height: 14.6px;
  width: 14.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.placeholder2 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 40px;
}
.placeholder-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.placeholder3 {
  position: relative;
  font-size: 10.2px;
  color: #64748b;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 37px;
}
.homeframe-parent13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 40px 0 0;
}
.chevron-right-icon {
  height: 14.6px;
  width: 14.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdown-label-wrapper2,
.sun-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.sun-parent {
  align-self: stretch;
  padding: 0 0.1px 0 0;
  gap: 5.8px;
}
.dropdown-label-wrapper2 {
  height: 43.5px;
  border-radius: 8.76px;
  background-color: #f1f5f9;
  overflow: hidden;
  padding: 8.7px 8.7px 8.8px 8px;
  box-sizing: border-box;
  gap: 16.1px;
}
.iconbox5 {
  height: 17.5px;
  width: 17.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon5 {
  height: 11.7px;
  width: 11.7px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot5 {
  height: 5.8px;
  width: 5.8px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.zap-icon {
  height: 14.6px;
  width: 14.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.placeholder4 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 29px;
}
.placeholder-homeframe {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.placeholder5 {
  position: relative;
  font-size: 10.2px;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 31px;
}
.homeframe-parent14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 48px 0 0;
}
.chevron-right-icon1 {
  height: 14.6px;
  width: 14.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdown-label-wrapper3,
.zap-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.zap-parent {
  align-self: stretch;
  gap: 5.8px;
}
.dropdown-label-wrapper3 {
  height: 43.5px;
  border-radius: 8.76px;
  background-color: #3b82f6;
  overflow: hidden;
  padding: 8.7px 8.7px 8.8px 8px;
  box-sizing: border-box;
  gap: 16.1px;
  color: #fff;
}
.dropdown-label-wrapper-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4px 0 0;
  gap: 5.8px 3.8px;
}
.iconbox6 {
  height: 17.5px;
  width: 17.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon6 {
  height: 11.7px;
  width: 11.7px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot6 {
  height: 5.8px;
  width: 5.8px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.apple {
  height: 18px;
  flex: 1;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 17.52px;
  display: inline-block;
}
.dropdown-label-wrapper4 {
  width: 228px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5.8px 14.6px;
  box-sizing: border-box;
  gap: 5.8px;
  color: #3f4b51;
  font-family: "Messina Sans";
}
.components-actionmenu,
.divgroup2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.components-actionmenu {
  width: 306.6px;
  border-radius: 12px 12px 0 0;
  background-color: #fff;
  border: 0.7px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  padding: 11.7px 11px 3.7px;
  gap: 11.7px;
  font-size: 11.7px;
  font-family: Inter;
}
.divgroup2 {
  align-self: stretch;
  border-radius: 16px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  justify-content: flex-start;
  padding: 38px 45px 0.5px;
  gap: 12px;
}
.feedback-to-job {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.feedback-to-job-applicants-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4px 0 7.2px;
}
.jobify-could-provide {
  align-self: stretch;
  position: relative;
  font-size: 15px;
  line-height: 24px;
  font-family: Inter;
  text-align: center;
}
.homeframe-parent15 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;
}
.star-icon {
  height: 27.8px;
  width: 27.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.star {
  height: 37px;
  border-radius: 7px;
  background-color: #22c55e;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.6px 5px 4.6px 4.6px;
  box-sizing: border-box;
  z-index: 1;
}
.star-icon1 {
  height: 27.8px;
  width: 27.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.star1 {
  height: 37px;
  border-radius: 7px;
  background-color: #22c55e;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.6px 5px 4.6px 4.6px;
  box-sizing: border-box;
  z-index: 1;
}
.star-icon2 {
  height: 27.8px;
  width: 27.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.star2 {
  height: 37px;
  border-radius: 7px;
  background-color: #22c55e;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.6px 5px 4.6px 4.6px;
  box-sizing: border-box;
  z-index: 1;
}
.star-icon3 {
  height: 27.8px;
  width: 27.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.star3 {
  height: 37px;
  border-radius: 7px;
  background-color: #22c55e;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.6px 5px 4.6px 4.6px;
  box-sizing: border-box;
  z-index: 1;
}
.star-icon4 {
  height: 27.8px;
  width: 27.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.star4 {
  height: 37px;
  border-radius: 7px;
  background-color: #22c55e;
  justify-content: flex-start;
  padding: 4.6px 5px 4.6px 4.6px;
  box-sizing: border-box;
  z-index: 1;
}
.divrelative-inner1,
.star-parent,
.star4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.star-parent {
  justify-content: flex-start;
  gap: 4.6px;
}
.divrelative-inner1 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 46.3px 0 46px;
}
.divgroup3,
.divrelative4 {
  background-color: #f1f5f9;
  box-sizing: border-box;
}
.divrelative4 {
  flex: 1;
  border-radius: 15px;
  border: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 33px 56px 49px 45px;
  gap: 41px;
  max-width: 100%;
}
.divgroup3 {
  align-self: stretch;
  border-radius: 16px;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px;
}
.divgroup-group,
.divgroup3,
.homeframe-parent6,
.homeframe-wrapper6 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.divgroup-group {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  min-width: 308px;
}
.homeframe-parent6,
.homeframe-wrapper6 {
  flex-direction: row;
}
.homeframe-parent6 {
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 24.2px;
}
.homeframe-wrapper6 {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 80px;
  box-sizing: border-box;
  color: #1e293b;
}
.heading-2 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
}
.heading-2-multi-window-suppo-wrapper {
  margin-left: -264.59999999999945px;
  width: 249.4px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13.4px 0 0;
  box-sizing: border-box;
}
.div1 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
  white-space: nowrap;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.5px 0 0;
  color: #e4e5eb;
}
.div2,
.heading-21 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
}
.heading-21 {
  min-width: 60px;
  flex-shrink: 0;
}
.div2 {
  min-width: 9px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.4px 0 0;
  color: #e4e5eb;
}
.heading-22 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
}
.heading-2-deep-linking-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 6.8px 0 0;
}
.div3 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
  white-space: nowrap;
}
.homeframe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.4px 0 0;
  color: #e4e5eb;
}
.heading-23 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
}
.heading-2-launch-at-startup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 9px 0 0;
}
.div4 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
  white-space: nowrap;
}
.wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.5px 0 0;
  color: #e4e5eb;
}
.heading-24 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
}
.heading-2-screen-recording-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 11px 0 0;
}
.div5 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
  white-space: nowrap;
}
.wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.4px 0 0;
  color: #e4e5eb;
}
.heading-25 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
}
.heading-2-code-signing-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8.5px 0 0;
}
.div6 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
  white-space: nowrap;
}
.wrapper3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.4px 0 0;
  color: #e4e5eb;
}
.heading-26 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
}
.heading-2-offline-support-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8.2px 0 0;
}
.div7 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  color: #e4e5eb;
  display: inline-block;
  min-width: 9px;
  white-space: nowrap;
  flex-shrink: 0;
}
.divmx-0 {
  width: 1280px;
  border-radius: 12px;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 33px 8px 33px 73.4px;
  box-sizing: border-box;
  gap: 15.2px;
  max-width: 100%;
}
.homeframe-child6 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90.02deg,
    #fff,
    rgba(255, 255, 255, 0) 53.13%,
    #fff
  );
  z-index: 1;
}
.divmx-0-parent,
.homeframe-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.divmx-0-parent {
  flex-direction: row;
  padding: 1px 80px;
  position: relative;
  color: #64748b;
}
.homeframe-section {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 110px;
  gap: 46px;
  flex-shrink: 0;
  text-align: left;
  font-size: 24px;
  color: #000;
  font-family: Roboto;
}
.loved-by-founders {
  margin: 0;
  width: 614px;
  position: relative;
  font-size: inherit;
  line-height: 60px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 1280px;
}
.a775098484f006bcded-g220widge-icon {
  height: 46px;
  width: 228px;
  position: relative;
  object-fit: cover;
  max-width: 228px;
}
.link13 {
  cursor: pointer;
  border: 0;
  padding: 10px 0 0;
  background-color: transparent;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: 1216px;
}
.link-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}
.loved-by-founders-and-operator-parent {
  width: 1269px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  flex-shrink: 0;
  gap: 20px;
  font-family: "Work Sans";
}
.native-apis {
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  min-width: 126px;
  max-width: 334px;
}
.native-apis-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 16px;
}
.div8 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 9.4px;
  flex-shrink: 0;
  debug_commit: f6aba90;
}
.wrapper4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}
.seamless-integration-with,
.tapping-into-apis,
.using-our-existing,
.what-sets-todesktop {
  margin: 0;
}
.tray,
.what-sets-todesktop-container {
  position: relative;
  line-height: 28px;
  flex-shrink: 0;
  debug_commit: f6aba90;
}
.tray {
  display: inline-block;
  min-width: 37.099999999999454px;
  z-index: 1;
}
.input-handler {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 0 106px;
  margin-top: -28px;
}
.and {
  width: 37px;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  debug_commit: f6aba90;
  z-index: 1;
}
.condition-set,
.error-handler2 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.error-handler2 {
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 60px;
  margin-top: -28px;
}
.condition-set {
  flex-direction: column;
  justify-content: flex-start;
}
.notifications {
  display: inline-block;
  min-width: 107.60000000000036px;
}
.notifications,
.weve-crafted-an {
  position: relative;
  line-height: 28px;
}
.branching-logic {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}
.exceptionally-polished-desktop,
.experience {
  margin: 0;
}
.exceptionally-polished-desktop-container {
  height: 56px;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.div9 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 10px;
  z-index: 1;
}
.data-comparison,
.user-interface {
  display: flex;
  align-items: flex-start;
}
.user-interface {
  width: 225.8px;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: -26px;
  font-size: 24px;
  color: #f8fafc;
}
.data-comparison {
  height: 56px;
  flex-direction: column;
  justify-content: flex-start;
}
.data-processor1 {
  flex: 1;
  flex-direction: column;
  min-width: 206px;
  flex-shrink: 0;
  debug_commit: f6aba90;
  font-size: 18px;
  color: #1e293b;
}
.data-processor1,
.homeframe-parent17,
.homeframe-parent18 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.homeframe-parent18 {
  align-self: stretch;
  flex-direction: row;
  gap: 6.6px;
  color: black;
  font-family: Inter;
  font-weight: 200;
  font-size: 20px !important;
}
.homeframe-parent17 {
  width: 333px;
  flex-direction: column;
  gap: 39px;
  max-width: 100%;
}
.divflex12,
.rick2a2dd2eajpg-icon {
  height: 48px;
  width: 48px;
  border-radius: 9999px;
}
.rick2a2dd2eajpg-icon {
  position: relative;
  object-fit: cover;
  display: none;
  max-width: 48px;
  max-height: 48px;
}
.divflex12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-image: url(./images/rick2a2dd2eajpg@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.rick-pastoor,
.rise {
  position: relative;
  line-height: 24px;
  display: inline-block;
}
.rick-pastoor {
  font-weight: 600;
  min-width: 97px;
}
.rise {
  color: #64748b;
  min-width: 32px;
  z-index: 1;
}
.expression-parser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.task-scheduler {
  flex-direction: row;
  gap: 16px;
}
.divflex11,
.event-trigger1,
.task-scheduler {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.event-trigger1 {
  flex-direction: row;
  padding: 0 16px;
  font-size: 16px;
  font-family: Inter;
}
.divflex11 {
  flex: 1;
  border-radius: 15px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 40px 24px;
  gap: 79px;
  min-width: 313px;
  max-width: 100%;
  flex-shrink: 0;
}
.cleaner-less-cluttered {
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  max-width: 334px;
}
.cleaner-less-cluttered-ui-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 16px;
}
.div10 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 9.4px;
  flex-shrink: 0;
  debug_commit: f6aba90;
}
.wrapper5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}
.freedom-to-design,
.having-a-desktop,
.no-brainer-for-us,
.shortcuts-and-a,
.want-including-better {
  margin: 0;
}
.div11,
.having-a-desktop-container {
  position: relative;
  line-height: 28px;
}
.div11 {
  line-height: 20px;
  display: inline-block;
  min-width: 10px;
  z-index: 1;
}
.wrapper6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: -26px;
  font-size: 24px;
  color: #f8fafc;
}
.having-a-desktop-app-gives-us-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 209px;
  min-height: 140px;
  flex-shrink: 0;
  debug_commit: f6aba90;
  font-size: 18px;
  color: #1e293b;
}
.homeframe-parent19,
.homeframe-parent20 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent20 {
  align-self: stretch;
  flex-direction: row;
  gap: 6.6px;
  color: black;
  font-family: Inter;
  font-size: 20px;
}
.homeframe-parent19 {
  width: 337.5px;
  flex-direction: column;
  gap: 39px;
}
.divflex14,
.max003d3232jpg-icon {
  height: 48px;
  width: 48px;
  border-radius: 9999px;
}
.max003d3232jpg-icon {
  position: relative;
  object-fit: cover;
  display: none;
  max-width: 48px;
  max-height: 48px;
}
.divflex14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-image: url("./images/max003d3232jpg@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.basedash,
.max-musing {
  position: relative;
  line-height: 24px;
  display: inline-block;
}
.max-musing {
  font-weight: 600;
  min-width: 94px;
}
.basedash {
  color: #64748b;
  min-width: 74px;
}
.max-musing-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divflex-group {
  flex-direction: row;
  gap: 16px;
}
.divflex-group,
.divflex-inner,
.divflex13 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divflex-inner {
  flex-direction: row;
  padding: 0 16px;
  font-size: 16px;
  font-family: Inter;
}
.divflex13 {
  flex: 1;
  border-radius: 15px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 40px 24px;
  gap: 135px;
  min-width: 313px;
  max-width: 100%;
  flex-shrink: 0;
}
.code-optional {
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  max-width: 334px;
}
.code-optional-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 16px;
}
.div12 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 9.4px;
  flex-shrink: 0;
  debug_commit: f6aba90;
}
.wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}
.a-desktop-app,
.able-to-extend,
.code-once-we,
.didnt-have-to,
.it-was-unbelievably,
.todesktops-libraries,
.with-custom-behavior {
  margin: 0;
}
.div13,
.it-was-unbelievably-container {
  position: relative;
  line-height: 28px;
}
.div13 {
  line-height: 20px;
  display: inline-block;
  min-width: 10px;
  z-index: 1;
}
.wrapper8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 0 70px;
  margin-top: -26px;
  font-size: 24px;
  color: #f8fafc;
}
.it-was-unbelievably-simple-for-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 220px;
  min-height: 196px;
  flex-shrink: 0;
  debug_commit: f6aba90;
  font-size: 18px;
  color: #1e293b;
}
.homeframe-parent21,
.homeframe-parent22 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent22 {
  align-self: stretch;
  flex-direction: row;
  gap: 6.6px;
  color: black;
  font-family: Inter;
  font-size: 20px;
}
.homeframe-parent21 {
  width: 355px;
  flex-direction: column;
  gap: 39px;
}
.divflex16,
.pouya82a9db24jpg-icon {
  height: 48px;
  width: 48px;
  border-radius: 9999px;
}
.pouya82a9db24jpg-icon {
  position: relative;
  object-fit: cover;
  display: none;
  max-width: 48px;
  max-height: 48px;
}
.divflex16 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-image: url(./images/pouya82a9db24jpg@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.lifeat,
.pouya-rad {
  position: relative;
  line-height: 24px;
  display: inline-block;
}
.pouya-rad {
  font-weight: 600;
  min-width: 82px;
}
.lifeat {
  color: #64748b;
  min-width: 44px;
}
.pouya-rad-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divflex-container {
  flex-direction: row;
  gap: 16px;
}
.divflex-child,
.divflex-container,
.divflex-parent,
.divflex15 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divflex-child {
  flex-direction: row;
  padding: 0 16px;
  font-size: 16px;
  font-family: Inter;
}
.divflex-parent,
.divflex15 {
  max-width: 100%;
  flex-shrink: 0;
}
.divflex15 {
  flex: 1;
  border-radius: 15px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 40px 24px;
  gap: 79px;
  min-width: 313px;
}
.divflex-parent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 24px;
}
.ready-for-revolution {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 472px;
}
.portal-app-for,
.use-our-bestest {
  margin: 0;
}
.superscript,
.use-our-bestest-container {
  position: relative;
  letter-spacing: -1.2px;
  font-weight: 600;
}
.use-our-bestest-container {
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}
.superscript {
  line-height: 32px;
  display: inline-block;
  min-width: 11px;
  z-index: 1;
}
.state-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 34px;
  margin-top: -44px;
  font-size: 24px;
}
.transition-condition,
.transition-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.transition-table {
  align-self: stretch;
  min-height: 96px;
  font-size: 48px;
}
.transition-condition {
  width: 418px;
  gap: 12px;
  max-width: 100%;
}
.few-minutes,
.the-process-of,
.todesktop-builder-will {
  margin: 0;
}
.todesktop-builder-will-container {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-family: Inter;
  display: inline-block;
  max-width: 472px;
}
.state-transition {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  max-width: 100%;
}
.try-demo {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  font-family: Roboto;
  color: #1e293b;
  text-align: left;
  display: inline-block;
  min-width: 76px;
}
.link14 {
  cursor: pointer;
  border: 0;
  padding: 13px 24px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}
.link14:hover {
  background-color: #e6e6e6;
}
.superscript1 {
  height: 0;
  position: relative;
  display: inline-block;
  min-width: 5px;
}
.semaphore {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.5px 0 0;
}
.demo-is-free {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  color: #e2e8f0;
  margin-left: -0.5px;
}
.event-dispatcher,
.event-queue,
.mutex-manager,
.signal-processor {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.event-dispatcher,
.event-queue,
.signal-processor {
  flex-direction: column;
}
.event-queue {
  gap: 24px;
  font-size: 9px;
  color: #d0d1d6;
  font-family: Inter;
}
.event-dispatcher,
.signal-processor {
  max-width: 100%;
}
.signal-processor {
  gap: 49px;
}
.event-dispatcher {
  padding: 30px 0 0;
  box-sizing: border-box;
  min-width: 461px;
}
.divaspect-w-5margin {
  height: 547px;
  width: 632px;
  position: relative;
  display: none;
  max-width: 100%;
  z-index: 1;
}
.group-icon1,
.svg-icon1 {
  position: absolute;
  margin: 0 !important;
  flex-shrink: 0;
  object-fit: cover;
}
.svg-icon1 {
  height: 501px;
  width: 1501px;
  top: -24px;
  right: -686.5px;
  overflow: hidden;
  z-index: 1;
}
.group-icon1 {
  width: 1153.6px;
  height: 865px;
  right: -501.6px;
  bottom: -368px;
}
.group-icon2 {
  height: 24.7px;
  width: 24.8px;
  position: relative;
  min-height: 25px;
}
.jobify3 {
  position: relative;
  line-height: 23px;
  font-weight: 600;
  display: inline-block;
  min-width: 51px;
  white-space: nowrap;
}
.link15,
.spanml-2margin1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3px 0 6.4px;
}
.link15 {
  height: 33.6px;
  flex-direction: row;
  align-items: center;
  padding: 4.5px 131.3px 4.4px 4.8px;
  box-sizing: border-box;
  gap: 3.2px;
  font-size: 16px;
  font-family: Exo;
}
.iconbox7 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon7 {
  height: 12.8px;
  width: 12.8px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot7 {
  height: 6.4px;
  width: 6.4px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.placeholder6 {
  position: relative;
  font-size: 11.2px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 27px;
}
.dropdown-label-wrapper5 {
  cursor: pointer;
  border: 0;
  padding: 11.2px 9.6px;
  background-color: #1e293b;
  border-radius: 72.1px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 17.6px;
}
.iconbox8 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.avatarbank16light-icon8 {
  height: 12.8px;
  width: 12.8px;
  position: relative;
  object-fit: cover;
  display: none;
}
.status-dot8 {
  height: 6.4px;
  width: 6.4px;
  position: relative;
  border-radius: 50%;
  background-color: #ffb400;
  border: 0 solid #6a7381;
  box-sizing: border-box;
  display: none;
}
.placeholder7 {
  position: relative;
  display: inline-block;
  mix-blend-mode: normal;
  min-width: 41px;
}
.dropdown-label-wrapper6 {
  border-radius: 72.1px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11.2px 9.6px;
  gap: 17.6px;
}
.buffer-manager {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
  color: #64748b;
}
.avatarssingle-icon1 {
  height: 25.6px;
  width: 25.6px;
  border-radius: 64.09px;
  object-fit: cover;
}
.singhc102gmailcom1 {
  align-self: stretch;
  height: 14px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
.input-filter {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 77px;
}
.cp,
.divflex17 {
  align-self: stretch;
  align-items: center;
}
.cp {
  flex: 1;
  position: relative;
  display: flex;
}
.divflex17 {
  width: 14px;
  padding: 2.4px 0;
  box-sizing: border-box;
}
.data-aggregator2,
.divflex17,
.divm-111,
.event-router {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.divm-111 {
  height: 20px;
  width: 26.8px;
  border-radius: 72.1px;
  background-color: #fff6cc;
  border: 0.8px solid rgba(102, 83, 0, 0.1);
  box-sizing: border-box;
  align-items: flex-start;
  padding: 1.6px 5px 1.6px 6.4px;
  text-align: left;
  font-size: 9.6px;
  color: #665300;
}
.data-aggregator2,
.event-router {
  flex: 1;
  align-items: center;
}
.data-aggregator2 {
  gap: 6.4px;
  flex-shrink: 0;
}
.event-router {
  border-radius: 72.1px;
  padding: 9.6px 9.5px 9.7px 9.7px;
}
.function-caller-icon,
.settings-icon {
  height: 19.2px;
  width: 0;
  position: relative;
  fill: var(--primary-text-color)
}
.settings-icon {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.constant-holder {
  height: 35.3px;
  width: 35.3px;
  border-radius: 72.1px;
  justify-content: flex-start;
  padding: 9.6px 9.7px 9.7px 9.6px;
}
.constant-holder,
.message-broker,
.resource-manager {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.message-broker {
  width: 258px;
  justify-content: flex-start;
  padding: 0;
  gap: 9.6px;
  text-align: right;
  color: #475569;
}
.resource-manager {
  width: 1153.6px;
  border-bottom: 0.8px solid #e2e8f0;
  justify-content: space-between;
  padding: 9.6px 16px;
  flex-shrink: 0;
  debug_commit: f6aba90;
  max-width: 177%;
  gap: 20px;
  z-index: 1;
}
.create-a-job,
.step-14 {
  position: relative;
  z-index: 1;
}
.create-a-job {
  font-weight: 600;
  display: inline-block;
  min-width: 99px;
}
.step-14 {
  align-self: stretch;
  font-size: 11.2px;
  color: #64748b;
}
.create-a-job-parent {
  width: 640.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
  max-width: 103%;
  flex-shrink: 0;
}
.buildings-icon1 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.company-details1 {
  position: relative;
  font-size: 11.2px;
  font-weight: 600;
  font-family: Inter;
  color: #1e293b;
  text-align: left;
  display: inline-block;
  min-width: 91px;
}
.buildings-parent {
  cursor: pointer;
  border: 0;
  padding: 9.6px 7px 9.7px;
  background-color: transparent;
  flex: 0.9507;
  border-bottom: 1.6px solid #3b82f6;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6.4px;
  min-width: 84px;
}
.buildings-parent:hover {
  background-color: rgba(84, 156, 255, 0.09);
}
.arrow-chevron-down-icon7,
.briefcase-icon1 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
}
.arrow-chevron-down-icon7 {
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.briefcase-icon1 {
  object-fit: cover;
}
.job-details2 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 60px;
}
.briefcase-parent {
  flex: 0.7036;
  border-radius: 72.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9.6px 22px 9.7px 22.1px;
  box-sizing: border-box;
  gap: 6.4px;
  min-width: 84px;
}
.arrow-chevron-down-icon8 {
  object-fit: contain;
}
.arrow-chevron-down-icon8,
.iconsaxlinearusersearch1 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.candidate-details1 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 96px;
}
.iconsaxlinearusersearch-parent {
  flex: 1;
  border-radius: 72.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9.6px 4px 9.7px;
  box-sizing: border-box;
  gap: 6.4px;
  min-width: 84px;
}
.arrow-chevron-down-icon9 {
  object-fit: contain;
}
.arrow-chevron-down-icon9,
.iconsaxlinearmessagetext1 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.job-description1 {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.iconsaxlinearmessagetext-parent {
  flex: 0.9015;
  border-radius: 72.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9.6px 10px 9.7px;
  box-sizing: border-box;
  gap: 6.4px;
  min-width: 84px;
}
.homeframe-parent26,
.vector-icon {
  align-self: stretch;
  overflow: hidden;
  flex-shrink: 0;
}
.homeframe-parent26 {
  box-shadow: 0 3.2px 32.05px rgba(9, 19, 21, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  gap: 6.4px;
}
.vector-icon {
  height: 0.8px;
  position: relative;
  max-width: 100%;
}
.homeframe-parent25 {
  align-self: stretch;
  height: 38.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.company-info {
  position: relative;
  font-size: 12.8px;
  font-weight: 600;
  color: #1e293b;
  display: inline-block;
  min-width: 86px;
  flex-shrink: 0;
}
.vuesaxlinearuser-square-icon13 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
  min-height: 19px;
}
.image-upload-icon {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  object-fit: cover;
}
.logo-update400x400 {
  width: 125px;
  position: relative;
  display: inline-block;
  min-width: 125px;
}
.vuesaxlinearuser-square-parent4 {
  height: 133px;
  border-radius: 6.41px;
  background-color: #f8fafc;
  border: 0.8px dashed #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 56.9px 73px 56.9px 76px;
  gap: 3.2px;
}
.company-name {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon14 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.jobify4 {
  flex: 1;
  position: relative;
}
.company-name-parent,
.vuesaxlinearuser-square-parent5 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  gap: 6.4px;
}
.vuesaxlinearuser-square-parent5 {
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #3b82f6;
  flex-direction: row;
  align-items: center;
  padding: 12.9px 8px 12.8px 12.8px;
  color: #1e293b;
}
.company-name-parent {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.company-size {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon15 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
  min-height: 19px;
}
.how-many-employees {
  flex: 1;
  position: relative;
}
.arrow-chevron-down-icon10 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.output-processor {
  align-self: stretch;
  flex: 1;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 11.2px 8px 11.3px 12.8px;
  gap: 6.4px;
  color: #94a3b8;
}
.company-size-parent,
.homeframe-parent28 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.company-size-parent {
  align-self: stretch;
  height: 61.7px;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 6.4px;
}
.homeframe-parent28 {
  flex: 1;
  justify-content: center;
  gap: 9.6px;
  min-width: 194px;
  text-align: left;
}
.homeframe-parent27 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 19.2px;
  flex-shrink: 0;
  text-align: center;
}
.company-website {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.jobify5,
.vuesaxlinearuser-square-icon16 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.jobify5 {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 11.2px;
  background-color: transparent;
  height: 16px;
  flex: 1;
  color: #1e293b;
  text-align: left;
  display: inline-block;
  min-width: 168px;
  padding: 0;
}
.branch-splitter,
.value-comparator {
  display: flex;
  justify-content: flex-start;
  gap: 6.4px;
}
.value-comparator {
  align-self: stretch;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #3b82f6;
  flex-direction: row;
  align-items: center;
  padding: 12.9px 8px 12.8px 12.8px;
}
.branch-splitter {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 197px;
}
.company-linkedin {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon17 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
  min-height: 19px;
}
.lidkencomjupiter {
  flex: 1;
  position: relative;
}
.arrow-chevron-down-icon11 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vuesaxlinearuser-square-parent6 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 11.3px 8px 11.2px 12.8px;
  gap: 6.4px;
  color: #1e293b;
}
.branch-splitter1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
  min-width: 197px;
}
.company-info-parent,
.sequence-connector {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9.6px;
  flex-shrink: 0;
}
.company-info-parent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 19.2px;
}
.your-name {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.john-smith,
.vuesaxlinearuser-square-icon18 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.john-smith {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 11.2px;
  background-color: transparent;
  height: 16px;
  flex: 1;
  color: #94a3b8;
  text-align: left;
  display: inline-block;
  min-width: 168px;
  padding: 0;
}
.vuesaxlinearuser-square-parent7 {
  align-self: stretch;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12.9px 8px 12.8px 12.8px;
  gap: 6.4px;
}
.your-name-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
  min-width: 197px;
}
.employees {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.vuesaxlinearuser-square-icon19 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.how-many-employees1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.vuesaxlinearuser-square-parent8 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 12.9px 8px 12.8px 12.8px;
  gap: 6.4px;
  white-space: nowrap;
  color: #94a3b8;
}
.employees-parent,
.homeframe-parent29,
.vuesaxlinearuser-square-parent8 {
  display: flex;
  justify-content: flex-start;
}
.employees-parent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.4px;
  min-width: 197px;
}
.homeframe-parent29 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 9.6px;
}
.message-printer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
}
.function-return-child {
  align-self: stretch;
  height: 0;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.city,
.job-location {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.job-location {
  width: 80px;
  height: 16px;
}
.city {
  align-self: stretch;
  height: 14px;
}
.vuesaxlinearuser-square-icon20 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.ny {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.city-parent,
.vuesaxlinearuser-square-parent9 {
  display: flex;
  justify-content: flex-start;
  gap: 6.4px;
}
.vuesaxlinearuser-square-parent9 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 12.8px 8px 12.9px 12.8px;
  color: #94a3b8;
}
.city-parent {
  width: 146.6px;
  flex-direction: column;
  align-items: flex-start;
}
.state {
  align-self: stretch;
  height: 14px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon21 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.ny1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.state-parent,
.vuesaxlinearuser-square-parent10 {
  display: flex;
  justify-content: flex-start;
  gap: 6.4px;
}
.vuesaxlinearuser-square-parent10 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 12.8px 8px 12.9px 12.9px;
  color: #94a3b8;
}
.state-parent {
  width: 146.6px;
  flex-direction: column;
  align-items: flex-start;
}
.country {
  align-self: stretch;
  height: 14px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon22 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.usa {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.vuesaxlinearuser-square-parent11 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12.8px 8px 12.9px 12.8px;
  gap: 6.4px;
  color: #94a3b8;
}
.country-parent {
  width: 146.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
}
.company-street-address {
  align-self: stretch;
  height: 14px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon23 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.ny-usa {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.vuesaxlinearuser-square-parent12 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12.8px 8px 12.9px 12.8px;
  gap: 6.4px;
  white-space: nowrap;
  color: #94a3b8;
}
.company-street-address-parent,
.homeframe-parent30 {
  width: 146.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
}
.homeframe-parent30 {
  width: 610.2px;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  gap: 9.6px;
  max-width: 100%;
  font-size: 11.2px;
  color: #64748b;
}
.function-return-item,
.job-location-parent {
  align-self: stretch;
  max-width: 100%;
  flex-shrink: 0;
}
.job-location-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 5px 0 0;
  box-sizing: border-box;
  gap: 9.6px;
  font-size: 12.8px;
  color: #1e293b;
}
.function-return-item {
  height: 0;
  position: relative;
  overflow: hidden;
}
.city1,
.company-location {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.company-location {
  width: 116px;
  height: 16px;
}
.city1 {
  align-self: stretch;
  height: 14px;
}
.vuesaxlinearuser-square-icon24 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.ny2 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.city-group,
.vuesaxlinearuser-square-parent13 {
  display: flex;
  justify-content: flex-start;
  gap: 6.4px;
}
.vuesaxlinearuser-square-parent13 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 12.8px 8px 12.9px 12.8px;
  color: #94a3b8;
}
.city-group {
  width: 146.6px;
  flex-direction: column;
  align-items: flex-start;
}
.state1 {
  align-self: stretch;
  height: 14px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon25 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.ny3 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.state-group,
.vuesaxlinearuser-square-parent14 {
  display: flex;
  justify-content: flex-start;
  gap: 6.4px;
}
.vuesaxlinearuser-square-parent14 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 12.8px 8px 12.9px 12.9px;
  color: #94a3b8;
}
.state-group {
  width: 146.6px;
  flex-direction: column;
  align-items: flex-start;
}
.country1 {
  align-self: stretch;
  height: 14px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon26 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.usa1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.vuesaxlinearuser-square-parent15 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12.8px 8px 12.9px 12.8px;
  gap: 6.4px;
  color: #94a3b8;
}
.country-group {
  width: 146.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
}
.company-street-address1 {
  align-self: stretch;
  height: 14px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.vuesaxlinearuser-square-icon27 {
  height: 19.2px;
  width: 19.2px;
  position: relative;
  display: none;
}
.ny-usa1 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.vuesaxlinearuser-square-parent16 {
  align-self: stretch;
  height: 41.7px;
  border-radius: 6.41px;
  background-color: #fff;
  border: 0.8px solid #e2e8f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12.8px 8px 12.9px 12.8px;
  gap: 6.4px;
  white-space: nowrap;
  color: #94a3b8;
}
.company-street-address-group,
.homeframe-parent31 {
  width: 146.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.4px;
}
.homeframe-parent31 {
  width: 610.2px;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  gap: 9.6px;
  max-width: 100%;
  font-size: 11.2px;
  color: #64748b;
}
.company-location-parent,
.function-return {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.company-location-parent {
  align-self: stretch;
  padding: 0 5px 0 0;
  box-sizing: border-box;
  gap: 9.6px;
  flex-shrink: 0;
  font-size: 12.8px;
  color: #1e293b;
}
.function-return {
  flex: 1;
  gap: 19.2px;
}
.function-return-wrapper {
  align-self: stretch;
  border-radius: 12.82px;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding: 12.8px;
  max-width: 100%;
}
.homeframe-parent23,
.homeframe-parent24,
.function-return-wrapper,
.jobs-page-create-inner {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
}
.homeframe-parent24 {
  width: 640.9px;
  border-radius: 12.82px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 6.4px 0 0;
  gap: 3.2px;
  max-width: 103%;
  z-index: 1;
  font-size: 11.2px;
  color: #64748b;
}
.homeframe-parent23,
.jobs-page-create-inner {
  align-items: flex-start;
}
.homeframe-parent23 {
  width: 623px;
  flex-direction: column;
  padding: 0;
  gap: 14.3px;
  debug_commit: f6aba90;
}
.jobs-page-create-inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 109px;
  max-width: 100%;
  font-size: 16px;
}
.divrelative5,
.jobs-page-create {
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
}
.jobs-page-create {
  height: 497px;
  width: 652px;
  border-radius: 25.64px 0 20px 0;
  background-color: #f1f5f9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 432.9px;
  gap: 28.8px;
  min-width: 652px;
  max-width: 100%;
  z-index: 2;
  font-size: 11.2px;
  color: #1e293b;
  font-family: Inter;
}
.divrelative5 {
  align-self: stretch;
  border-radius: 20px;
  background-color: #3b82f6;
  box-shadow: 0 4px 40px rgba(23, 25, 34, 0.06);
  border: 1px solid #e2e8f0;
  overflow: hidden;
  flex-direction: row;
  padding: 50px 80px 0;
  gap: 87.5px;
  font-size: 16px;
  color: #fff;
}
.divrelative5,
.homeframe-parent16,
.home-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent16 {
  width: 1280.5px;
  flex-direction: column;
  gap: 109px;
}
.home-inner {
  flex-direction: row;
  padding: 0 80px 137px 79.5px;
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: 64px;
  color: #1e293b;
  font-family: Roboto;
}
.choose-a-plan,
.your-needs {
  margin: 0;
}
.choose-a-plan-container,
.free {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 60px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 1280px;
}
.free {
  line-height: 40px;
  min-width: 71px;
  max-width: 339.3299865722656px;
}
.heading-3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 36px;
}
.before-deploying-to,
.for-personal-use {
  margin: 0;
}
.for-personal-use-container {
  position: relative;
  line-height: 22.5px;
  display: inline-block;
  max-width: 339.3299865722656px;
}
.pmt-4,
.pmt-4margin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px 0 0;
}
.pmt-4margin {
  padding: 16px 0 0;
  color: #64748b;
  font-family: Inter;
}
.key-features {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: uppercase;
  display: inline-block;
  min-width: 114px;
  max-width: 339.3299865722656px;
}
.heading-6,
.heading-6margin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.heading-6margin {
  padding: 40px 0 0;
  font-size: 16px;
  color: #64748b;
}
.svg-icon2 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.free-for-personal {
  position: relative;
  line-height: 28px;
  white-space: nowrap;
}
.item,
.spanml-3margin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 131.2px 0 0;
}
.svg-icon3 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.run-app-locally {
  position: relative;
  line-height: 28px;
  display: inline-block;
  min-width: 129px;
}
.item1,
.spanml-3margin1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item1 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 183px 0 0;
}
.svg-icon4 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.no-code-signing {
  position: relative;
  line-height: 28px;
}
.item2,
.spanml-3margin2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item2 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 169px 0 0;
}
.svg-icon5 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.no-native-installers {
  position: relative;
  line-height: 28px;
}
.spanml-3margin3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item3,
.list,
.listmargin {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.item3 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 146px 0 0;
}
.list,
.listmargin {
  flex-direction: column;
  align-items: flex-start;
}
.list {
  flex: 1;
  padding: 0 0 34.5px;
  gap: 12px;
}
.listmargin {
  height: 206.5px;
  padding: 24px 0 0;
  box-sizing: border-box;
  font-family: Inter;
}
.get-started {
  width: 100%;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #3b82f6;
  text-align: center;
  display: inline-block;
  min-width: 92px;
  max-width: 275.3299865722656px;
}
.divrelative6,
.link16,
.linkmargin {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.link16 {
  cursor: pointer;
  border: 1px solid #e2e8f0;
  padding: 12px 20px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
}
.link16:hover,
.link17:hover {
  background-color: #e6e6e6;
  border: 1px solid #c9cfd6;
  box-sizing: border-box;
}
.divrelative6,
.linkmargin {
  align-items: flex-start;
}
.linkmargin {
  align-self: stretch;
  padding: 40px 0 0;
}
.divrelative6 {
  flex: 0.8372;
  border-radius: 16px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  padding: 33px 32px;
  min-width: 304px;
  max-width: 100%;
  font-size: 18px;
}
.essential {
  margin: 0;
  font-size: inherit;
  line-height: 40px;
  font-weight: 600;
  font-family: inherit;
  max-width: 339.3299865722656px;
}
.div14,
.essential,
.for-simple-desktop {
  position: relative;
  display: inline-block;
}
.for-simple-desktop {
  line-height: 22.5px;
  max-width: 339.3299865722656px;
  max-height: 22.5px;
}
.div14 {
  line-height: 32px;
  min-width: 41px;
  white-space: nowrap;
}
.wrapper9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 1px;
}
.month {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-family: Inter;
  display: inline-block;
  min-width: 61px;
}
.homeframe-parent32 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5.8px;
  font-size: 24px;
  color: #1e293b;
  font-family: Roboto;
}
.essential-parent,
.for-simple-desktop-apps-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.for-simple-desktop-apps-parent {
  gap: 24px;
  font-size: 18px;
  color: #64748b;
  font-family: Inter;
}
.essential-parent {
  gap: 16px;
}
.key-features1 {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: uppercase;
  display: inline-block;
  min-width: 114px;
  max-width: 339.3299865722656px;
}
.svg-icon6 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.windows-mac {
  position: relative;
  line-height: 28px;
}
.item4,
.spanml-3margin4 {
  display: flex;
  justify-content: flex-start;
}
.spanml-3margin4 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  min-width: 132px;
}
.item4 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 0 120.7px 0 0;
  row-gap: 20px;
}
.svg-icon7 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.code-signing {
  position: relative;
  line-height: 28px;
  display: inline-block;
  min-width: 114px;
}
.item5,
.spanml-3margin5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item5 {
  align-self: stretch;
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 0 197.7px 0 0;
  box-sizing: border-box;
}
.svg-icon8 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.native-installers {
  position: relative;
  line-height: 28px;
}
.item6,
.spanml-3margin6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item6 {
  align-self: stretch;
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 0 175px 0 0;
  box-sizing: border-box;
}
.svg-icon9 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.emailchat-support {
  position: relative;
  line-height: 28px;
}
.item7,
.spanml-3margin7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item7 {
  align-self: stretch;
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 0 151px 0 0;
  box-sizing: border-box;
}
.list1 {
  gap: 12px;
}
.key-features-parent,
.list1,
.listmargin1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.listmargin1 {
  padding: 24px 0 0;
  font-size: 18px;
  color: #1e293b;
  font-family: Inter;
}
.key-features-parent {
  font-size: 16px;
  color: #64748b;
}
.get-started1 {
  width: 92px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #3b82f6;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 92px;
  max-width: 275.3299865722656px;
}
.divrelative7,
.link17 {
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: flex-start;
}
.link17 {
  cursor: pointer;
  padding: 12px 20px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
}
.divrelative7 {
  flex: 0.8372;
  border-radius: 16px;
  background-color: #f1f5f9;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 33px 32px;
  gap: 40px;
  min-width: 304px;
  max-width: 100%;
}
.professional {
  margin: 0;
  font-size: inherit;
  line-height: 40px;
  font-weight: 600;
  font-family: inherit;
  max-width: 339.3399963378906px;
}
.div15,
.for-sophisticated-desktop,
.professional {
  position: relative;
  display: inline-block;
}
.for-sophisticated-desktop {
  line-height: 22.5px;
  max-width: 339.3399963378906px;
  max-height: 22.5px;
}
.div15 {
  line-height: 32px;
  min-width: 54px;
  white-space: nowrap;
}
.wrapper10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 1px;
}
.month1 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-family: Inter;
  display: inline-block;
  min-width: 61px;
}
.homeframe-parent33 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7.8px;
  font-size: 24px;
  color: #1e293b;
  font-family: Roboto;
}
.for-sophisticated-desktop-apps-parent,
.professional-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.for-sophisticated-desktop-apps-parent {
  gap: 24px;
  font-size: 18px;
  color: #64748b;
  font-family: Inter;
}
.professional-parent {
  gap: 16px;
}
.everything-in-essential {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 339.3399963378906px;
}
.svg-icon10 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.plugins {
  position: relative;
  line-height: 28px;
  display: inline-block;
  min-width: 62px;
  white-space: nowrap;
}
.item8,
.spanml-3margin8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item8 {
  align-self: stretch;
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 0 250px 0 0;
  box-sizing: border-box;
}
.svg-icon11 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.branded-download-links {
  position: relative;
  line-height: 28px;
}
.item9,
.spanml-3margin9 {
  display: flex;
  justify-content: flex-start;
}
.spanml-3margin9 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  min-width: 141px;
}
.item9 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 0 106px 0 0;
  row-gap: 20px;
}
.svg-icon12 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.analytics {
  position: relative;
  line-height: 28px;
  display: inline-block;
  min-width: 78px;
  white-space: nowrap;
}
.item10,
.spanml-3margin10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 12px;
}
.item10 {
  align-self: stretch;
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 0 234px 0 0;
  box-sizing: border-box;
}
.svg-icon13 {
  height: 11px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.access-to-restricted {
  position: relative;
  line-height: 28px;
}
.item11,
.spanml-3margin11 {
  display: flex;
  justify-content: flex-start;
}
.spanml-3margin11 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  min-width: 140px;
}
.item11 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 0 107px 0 0;
  row-gap: 20px;
}
.list2 {
  gap: 12px;
}
.everything-in-essential-plus-parent,
.list2,
.listmargin2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.listmargin2 {
  padding: 24px 0 0;
  font-size: 18px;
  color: #1e293b;
  font-family: Inter;
}
.everything-in-essential-plus-parent {
  font-size: 16px;
  color: #64748b;
}
.get-started2 {
  width: 92px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 92px;
  max-width: 275.3399963378906px;
}
.divrelative8,
.link18 {
  display: flex;
  align-items: flex-start;
}
.link18 {
  cursor: pointer;
  border: 0;
  padding: 12px 20px;
  background-color: #3b82f6;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
}
.link18:hover {
  background-color: #549cff;
}
.divrelative8 {
  position: absolute;
  top: 15.5px;
  left: 0;
  border-radius: 16px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 33px 32px;
  gap: 40px;
}
.most-popular {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #3b82f6;
  text-align: left;
  display: inline-block;
  min-width: 90px;
  max-width: 90px;
}
.prounded-full {
  cursor: pointer;
  border: 0;
  padding: 6px 15.4px 6px 16px;
  background-color: #d5ddff;
  position: absolute;
  top: 0;
  left: 141.8px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  z-index: 1;
}
.prounded-full:hover {
  background-color: #bdc4e6;
}
.divrelative-group {
  height: 545px;
  flex: 1;
  position: relative;
  min-width: 304px;
  max-width: 100%;
}
.divrelative-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 32px;
  max-width: 100%;
  font-size: 36px;
}
.choose-a-plan-that-fits-your-n-parent,
.home-child {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.choose-a-plan-that-fits-your-n-parent {
  flex: 1;
  flex-direction: column;
  gap: 32.5px;
}
.home-child {
  align-self: stretch;
  flex-direction: row;
  padding: 0 80px 92px 79.5px;
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: 48px;
  color: #1e293b;
  font-family: Roboto;
}
.faqs,
.is-todesktop-for {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.faqs {
  margin: 0;
  font-size: inherit;
  line-height: 60px;
  font-family: inherit;
  min-width: 112px;
  max-width: 1280px;
}
.is-todesktop-for {
  line-height: 28px;
  max-width: 578px;
}
.svg-icon14 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-6 {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 24px;
}
.divgroup4,
.spanml-6margin,
.term-button {
  display: flex;
  align-items: flex-start;
}
.term-button {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup4 {
  flex: 1;
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 24px;
  max-width: 100%;
}
.will-my-app {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon15 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-61 {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin1,
.term-button1 {
  display: flex;
  align-items: flex-start;
}
.spanml-6margin1 {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 24px;
}
.term-button1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup-container,
.divgroup5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divgroup5 {
  flex: 1;
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 25px 24px;
  max-width: 100%;
}
.divgroup-container {
  width: 1280px;
  flex-direction: row;
  gap: 24px;
}
.can-i-try {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon16 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-62 {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin2 {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 24px;
}
.divgroup6,
.spanml-6margin2,
.term-button2 {
  display: flex;
  align-items: flex-start;
}
.term-button2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup6 {
  flex: 1;
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 24px;
  max-width: 100%;
}
.which-version-of {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon17 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-63 {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin3,
.term-button3 {
  display: flex;
  align-items: flex-start;
}
.spanml-6margin3 {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 24px;
}
.term-button3 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
}
.divgroup-parent1,
.divgroup7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divgroup7 {
  flex: 1;
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 25px 24px;
  max-width: 100%;
}
.divgroup-parent1 {
  width: 1280px;
  flex-direction: row;
  gap: 24px;
}
.do-you-collect {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon18 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-64,
.spanml-6margin4 {
  display: flex;
  justify-content: flex-start;
}
.spanml-64 {
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin4 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
}
.divgroup8,
.term-button4 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.term-button4 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup8 {
  flex: 1;
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 24px;
}
.what-support-do {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon19 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-65 {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin5,
.term-button5 {
  display: flex;
  align-items: flex-start;
}
.spanml-6margin5 {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 24px;
}
.term-button5 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup-parent2,
.divgroup9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divgroup9 {
  flex: 1;
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 25px 24px;
  max-width: 100%;
}
.divgroup-parent2 {
  width: 1280px;
  flex-direction: row;
  gap: 24px;
}
.does-todesktop-work {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon20 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-66,
.spanml-6margin6 {
  display: flex;
  justify-content: flex-start;
}
.spanml-66 {
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin6 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
}
.divgroup10,
.term-button6 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.term-button6 {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup10 {
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 24px;
}
.what-features-are {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon21 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-67,
.spanml-6margin7 {
  display: flex;
  justify-content: flex-start;
}
.spanml-67 {
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin7 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
}
.divgroup11,
.term-button7 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.term-button7 {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup11 {
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 24px;
}
.how-does-todesktop {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon22 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-68,
.spanml-6margin8 {
  display: flex;
  justify-content: flex-start;
}
.spanml-68 {
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin8 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
}
.divgroup-parent3,
.divgroup12,
.term-button8 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.term-button8 {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup-parent3,
.divgroup12 {
  flex-direction: column;
  justify-content: flex-start;
}
.divgroup12 {
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  padding: 25px 24px;
}
.divgroup-parent3 {
  gap: 16px;
}
.more-questions-visit {
  position: relative;
  line-height: 28px;
}
.more-questions-visit-our-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4.4px 0 0;
}
.docs {
  margin-bottom: -2px;
  position: relative;
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  min-width: 42px;
}
.link-wrapper2,
.link19 {
  display: flex;
  justify-content: flex-start;
}
.link19 {
  height: 22px;
  border-bottom: 1px dashed #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-end;
}
.link-wrapper2 {
  flex-direction: column;
  align-items: flex-start;
  padding: 3px 0 0;
  color: #1e293b;
}
.or {
  position: relative;
  line-height: 28px;
  display: inline-block;
  min-width: 31px;
  z-index: 1;
}
.or-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4.5px 0 0;
}
.send-us-a {
  margin-bottom: -2px;
  position: relative;
  line-height: 28px;
  font-weight: 500;
  flex-shrink: 0;
  debug_commit: f6aba90;
}
.link-wrapper3,
.link20 {
  display: flex;
  justify-content: flex-start;
}
.link20 {
  height: 22px;
  border-bottom: 1px dashed #e2e8f0;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-end;
  padding: 0;
}
.link-wrapper3 {
  flex-direction: column;
  align-items: flex-start;
  padding: 3px 0 0;
  color: #1e293b;
}
.input-processor {
  position: relative;
  line-height: 28px;
  display: inline-block;
  min-width: 5px;
  z-index: 1;
}
.homeframe-parent36,
.homeframe-parent37 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.homeframe-parent37 {
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  color: #64748b;
}
.homeframe-parent36 {
  flex: 1;
  flex-direction: column;
  gap: 32px;
}
.how-does-my {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 578px;
}
.svg-icon23 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.spanml-69,
.spanml-6margin9 {
  display: flex;
  justify-content: flex-start;
}
.spanml-69 {
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
  box-sizing: border-box;
}
.spanml-6margin9 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
}
.divgroup13,
.term-button9 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.term-button9 {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.divgroup13 {
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 24px;
}
.i-want-to,
.there-a-bulk {
  margin: 0;
}
.i-want-to-container {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.svg-icon24 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.svg-container {
  height: 27px;
  flex-direction: column;
  padding: 3px 0 0;
  box-sizing: border-box;
}
.divgroup14,
.output-merger,
.svg-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divgroup14 {
  border-radius: 12px;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  flex-direction: row;
  padding: 24px 24px 26px;
  gap: 64px;
}
.output-merger {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.homeframe-parent35 {
  width: 1280px;
  flex-direction: row;
  gap: 24px;
}
.divmx-auto,
.divmx-auto-wrapper,
.homeframe-parent34,
.homeframe-parent35 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.homeframe-parent34 {
  width: 1280px;
  overflow-x: auto;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
  font-size: 18px;
  font-family: Inter;
}
.divmx-auto,
.divmx-auto-wrapper {
  box-sizing: border-box;
}
.divmx-auto {
  flex-direction: column;
  padding: 80px 0;
  gap: 32px;
  max-width: 1280px;
}
.divmx-auto-wrapper {
  flex-direction: row;
  padding: 0 80px 0 79.5px;
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: 48px;
  color: #1e293b;
  font-family: Roboto;
}
.trusted-by-founders {
  width: 417px;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  max-width: 1216px;
  box-sizing: border-box;
  padding-right: 20px;
}
.div16 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.eab0a256640861b9f5b9dd-60f7ebb-icon {
  width: 100%;
  height: 20.8px;
  position: relative;
  max-width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.divw-node-4a48b877-f4cb-8e6e {
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3.1px 0 1.6px;
  box-sizing: border-box;
}
.eab0962c866855c132848f-610052c-icon {
  width: 100%;
  height: 20px;
  position: relative;
  max-width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.divw-node-4a48b877-f4cb-8e6e1 {
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3.5px 0 2px;
  box-sizing: border-box;
}
.e6dc9f4388e8c5603-prefect-logo-icon {
  height: 23px;
  width: 90px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.e6dc9f4388e8c5603-prefect-logo {
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 90px;
}
.divw-node-4a48b877-f4cb-8e6e2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 0 0.5px;
}
.f783728ecf6e37e93e3f92-image2-icon {
  width: 100%;
  height: 24px;
  position: relative;
  max-width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.divw-node-4a48b877-f4cb-8e6e3 {
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5px 5px 0 0;
  box-sizing: border-box;
}
.f783e330677765bba373fa-image2-icon {
  width: 100%;
  height: 21.5px;
  position: relative;
  max-width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.divw-node-4a48b877-f4cb-8e6e4 {
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2.7px 5px 1.3px 0;
  box-sizing: border-box;
}
.ff3d6781f2eee1227fa953-moonpay-icon {
  height: 16.4px;
  width: 89.9px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.ff3d6781f2eee1227fa953-moonpay {
  height: 16.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 90px;
}
.divw-node-4a48b877-f4cb-8e6e5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5.3px 0.1px 3.8px 0;
}
.f8d72b65df8a816d992f20-image2-icon {
  width: 100%;
  height: 22.1px;
  position: relative;
  max-width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.divw-node-4a48b877-f4cb-8e6e6 {
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2.4px 0 1px;
  box-sizing: border-box;
}
.f8d780d980e9c010dc84b3-image2-icon {
  width: 100%;
  height: 19.3px;
  position: relative;
  max-width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.divw-node-4a48b877-f4cb-8e6e7 {
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3.9px 0 2.3px;
  box-sizing: border-box;
}
.divw-layout-grid {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 40px 0 0;
  gap: 20px;
}
.divstartup-trusted-clients {
  position: absolute;
  bottom: 48.5px;
  left: calc(50% - 608px);
  width: 1216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-width: 1216px;
  z-index: 2;
}
.we-help-software {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 672px;
}
.pmx-auto,
.signup {
  text-align: center;
  display: flex;
}
.pmx-auto {
  position: absolute;
  bottom: 381px;
  left: 383.5px;
  width: 672px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 0.1px;
  box-sizing: border-box;
  max-width: 672px;
  z-index: 2;
  font-size: 20px;
  color: #1e293b;
}
.signup {
  width: 57px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  align-items: center;
  justify-content: center;
  min-width: 57px;
}
.link21 {
  cursor: pointer;
  border: 0;
  padding: 12px 34.8px 12px 35px;
  background-color: #3b82f6;
  position: absolute;
  bottom: 291px;
  left: 575.1px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.link21:hover {
  background-color: #549cff;
}
.read-faq {
  width: 78px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Roboto;
  color: #1e293b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 78px;
}
.link22 {
  cursor: pointer;
  border: 1px solid #e2e8f0;
  padding: 12px 33px;
  background-color: #fff;
  position: absolute;
  right: 576.1px;
  bottom: 291px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(9, 19, 21, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  z-index: 2;
}
.link22:hover {
  background-color: #e6e6e6;
  border: 1px solid #c9cfd6;
  box-sizing: border-box;
}
.svg-icon25 {
  height: 14px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.code-platform {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 110px;
}
.divflex18,
.spanml-2margin2 {
  display: flex;
  justify-content: flex-start;
}
.spanml-2margin2 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 8px;
}
.divflex18 {
  height: 24px;
  flex-direction: row;
  align-items: center;
}
.svg-icon26 {
  height: 12px;
  width: 13px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.windows-mac-linuxon {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.spanml-2margin3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 8px;
}
.divflex-parent1,
.divflex19 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.divflex19 {
  height: 24px;
  align-items: center;
}
.divflex-parent1 {
  position: absolute;
  top: 199px;
  left: calc(50% - 214px);
  align-items: flex-start;
  gap: 16px;
  z-index: 2;
  font-size: 16px;
  color: #64748b;
}
.divhero-section {
  align-self: stretch;
  height: 889px;
  position: relative;
  background: linear-gradient(-60.64deg, #e0e9f6, #fff);
  max-width: 100%;
}
.divhero-section-wrapper {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: 13.7px;
  color: #3e4c5b;
  font-family: Inter;
}
.group-icon3 {
  height: 30.8px;
  width: 31px;
  position: relative;
  min-height: 31px;
}
.jobify6 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 64px;
}
.link23,
.spanml-2margin4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 3.4px 0 8px;
}
.link23 {
  height: 42.8px;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  gap: 4px;
  color: #1e293b;
  font-family: Exo;
}
.platform {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  min-width: 65px;
  max-width: 292px;
}
.divfooter-title,
.divfooter-title-divmargin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divfooter-title-divmargin {
  padding: 0 0 20px;
  font-size: 12px;
  color: #1e293b;
}
.link-features {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 58px;
  max-width: 292px;
}
.divlinkbox,
.divlinkboxmargin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin {
  padding: 0 0 12px;
}
.link-integrations {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 79px;
  max-width: 292px;
}
.divlinkbox1,
.divlinkboxmargin1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin1 {
  padding: 0 0 12px;
}
.link-trust {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 121px;
  max-width: 292px;
}
.divlinkbox2,
.divlinkboxmargin2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin2 {
  padding: 0 0 12px;
  font-size: 13.9px;
}
.link-pricing {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 46px;
  max-width: 292px;
}
.divlinkbox3 {
  align-self: stretch;
}
.divlinkbox3,
.divlinkboxmargin3,
.divw-node-4a5aea71-b1c2-c09a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin3 {
  align-self: stretch;
  padding: 0 0 12px;
}
.divw-node-4a5aea71-b1c2-c09a {
  flex: 1;
  min-width: 219px;
  max-width: 292px;
  min-height: 332px;
}
.customers {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  min-width: 76px;
  max-width: 292px;
}
.divfooter-title-divmargin1,
.divfooter-title1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divfooter-title-divmargin1 {
  padding: 0 0 20px;
  font-size: 12px;
  color: #1e293b;
}
.link-customer {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 114px;
  max-width: 292px;
}
.divlinkbox4,
.divlinkboxmargin4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin4 {
  padding: 0 0 12px;
  font-size: 13.9px;
}
.link-causal {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 121px;
  max-width: 292px;
}
.divlinkbox5,
.divlinkboxmargin5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin5 {
  padding: 0 0 12px;
}
.link-causal1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 125px;
  max-width: 292px;
}
.divlinkbox6 {
  align-self: stretch;
}
.divfooter-box,
.divlinkbox6,
.divlinkboxmargin6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin6 {
  align-self: stretch;
  padding: 0 0 12px;
}
.divfooter-box {
  flex: 1;
  min-width: 219px;
  max-width: 292px;
  min-height: 332px;
  font-size: 13.7px;
}
.integrations {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  min-width: 89px;
  max-width: 292px;
}
.link24,
.linkmargin1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.link24 {
  align-self: stretch;
}
.linkmargin1 {
  width: 100%;
  padding: 0 0 20px;
  box-sizing: border-box;
  max-width: 292px;
  font-size: 12px;
  color: #1e293b;
}
.link-quickbooks {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 78px;
  max-width: 292px;
}
.divlinkbox7,
.divlinkboxmargin7 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin7 {
  padding: 0 0 12px;
}
.link-xero {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 31px;
  max-width: 292px;
}
.divlinkbox8,
.divlinkboxmargin8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin8 {
  padding: 0 0 12px;
}
.link-netsuite {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 58px;
  max-width: 292px;
}
.divlinkbox9,
.divlinkboxmargin9 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin9 {
  padding: 0 0 12px;
}
.link-salesforce {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 69px;
  max-width: 292px;
}
.divlinkbox10,
.divlinkboxmargin10 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin10 {
  padding: 0 0 12px;
  font-size: 13.8px;
}
.link-snowflake {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 68px;
  max-width: 292px;
}
.divlinkbox11 {
  align-self: stretch;
}
.divlinkbox11,
.divlinkboxmargin11,
.divw-node-f4e37f78-482b-ff80- {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin11 {
  align-self: stretch;
  padding: 0 0 12px;
  font-size: 13.9px;
}
.divw-node-f4e37f78-482b-ff80- {
  flex: 1;
  min-width: 219px;
  max-width: 292px;
  min-height: 332px;
}
.resources {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  min-width: 73px;
  max-width: 292px;
}
.divfooter-title-divmargin2,
.divfooter-title2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divfooter-title-divmargin2 {
  padding: 0 0 20px;
  font-size: 12px;
  color: #1e293b;
}
.link-blog {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 30px;
  max-width: 292px;
}
.divlinkbox12,
.divlinkboxmargin12 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin12 {
  padding: 0 0 12px;
}
.link-documentation {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 101px;
  max-width: 292px;
}
.divlinkbox13,
.divlinkboxmargin13 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin13 {
  padding: 0 0 12px;
}
.link-product {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 112px;
  max-width: 292px;
}
.divlinkbox14,
.divlinkboxmargin14 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin14 {
  padding: 0 0 12px;
  font-size: 13.9px;
}
.link-startup {
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 292px;
}
.divlinkbox15,
.divlinkboxmargin15 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin15 {
  padding: 0 0 12px;
  font-size: 13.9px;
}
.link-webinars {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 62px;
  max-width: 292px;
}
.divlinkbox16,
.divlinkboxmargin16 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin16 {
  padding: 0 0 12px;
}
.link-careers {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 52px;
  max-width: 292px;
}
.divlinkbox17,
.divlinkboxmargin17 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin17 {
  padding: 0 0 12px;
  font-size: 13.8px;
}
.link-privacy {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 92px;
  max-width: 292px;
}
.divlinkbox18,
.divlinkboxmargin18 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin18 {
  padding: 0 0 12px;
  font-size: 13.9px;
}
.link-terms {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 110px;
  max-width: 292px;
}
.divlinkbox19,
.divlinkboxmargin19 {
  align-self: stretch;
  flex-direction: column;
}
.divlinkbox19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divlinkboxmargin19 {
  padding: 0 0 12px;
  font-size: 13.7px;
}
.divfooter-box1,
.divlinkboxmargin19,
.divw-layout-grid1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divfooter-box1 {
  flex: 1;
  flex-direction: column;
  min-width: 219px;
  max-width: 292px;
}
.divw-layout-grid1 {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
  font-size: 14px;
}
.causal-inc-all {
  position: relative;
  line-height: 24px;
}
.divcopyright-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0.5px 0 0;
}
.e962652b149a9da8e494ac-vector-icon {
  height: 18px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.e962652b149a9da8e494ac-vector {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 20px;
}
.link25,
.linkmargin2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4.4px 0 1.6px;
  box-sizing: border-box;
  max-width: 100px;
}
.linkmargin2 {
  width: 40px;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  max-width: 120px;
}
.e96265f15a34f8f9a29563-vector-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.e96265f15a34f8f9a29563-vector {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 20px;
}
.link26,
.linkmargin3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3.4px 0 0.6px;
  box-sizing: border-box;
  max-width: 100px;
}
.linkmargin3 {
  width: 40px;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  max-width: 120px;
}
.f16a7267e5f61d7781b-linkedin2-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.f16a7267e5f61d7781b-linkedin2 {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 20px;
}
.link27 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3.4px 0 0.6px;
  box-sizing: border-box;
  max-width: 100px;
}
.divcopyright-social-div,
.divsocial-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.divsocial-div {
  width: 100px;
  align-items: center;
}
.divcopyright-social-div {
  align-self: stretch;
  border-top: 1px solid #e2e8f0;
  box-sizing: border-box;
  align-items: flex-end;
  min-height: 65px;
  gap: 20px;
  font-size: 13.7px;
}
.divcontainer,
.divfooter,
.home {
  display: flex;
  flex-direction: column;
}
.divcontainer {
  width: 100%;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  gap: 80px;
  max-width: 1216px;
}
.divfooter,
.home {
  justify-content: flex-start;
}
.divfooter {
  align-self: stretch;
  align-items: center;
  padding: 80px 20px 72px;
  flex-shrink: 0;
  text-align: left;
  font-size: 20px;
  color: #64748b;
  font-family: Inter;
}
.home {
  width: 100%;
  position: relative;
  border-radius: 32px;
  background-color: #fff;
  overflow: hidden;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 23px;
  letter-spacing: normal;
}
@media screen and (max-width: 1300px) {
  .siblings-and-self {
    display: none;
  }
  .ancestor-and-descendant {
    gap: 47px 94px;
  }
  .sibling-and-self {
    padding-bottom: 62px;
    box-sizing: border-box;
  }
  .ff41b3e9d8465bbeab4d9d-gridsv1 {
    padding-top: 36px;
    padding-bottom: 259px;
    box-sizing: border-box;
  }
  .to-desktop-handles-container {
    max-width: 100%;
  }
  .homeframe-wrapper5 {
    padding-left: 39px;
    padding-right: 39px;
    box-sizing: border-box;
  }
  .divmx-0 {
    padding-left: 36px;
    box-sizing: border-box;
  }
  .homeframe-section {
    padding-bottom: 71px;
    box-sizing: border-box;
  }
  .link13,
  .loved-by-founders {
    max-width: 100%;
  }
  .event-dispatcher {
    flex: 1;
  }
  .jobs-page-create {
    flex: 1;
    padding-bottom: 281px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .divrelative5 {
    flex-wrap: wrap;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  .home-inner {
    padding-bottom: 89px;
    box-sizing: border-box;
  }
  .choose-a-plan-container {
    max-width: 100%;
  }
  .divrelative-parent {
    flex-wrap: wrap;
  }
  .divcontainer,
  .divmx-auto,
  .divstartup-trusted-clients,
  .faqs,
  .trusted-by-founders {
    max-width: 100%;
  }
}
@media screen and (max-width: 1125px) {
  .button {
    max-width: 100%;
  }
  .connected-cluster-wrapper {
    padding-bottom: 90px;
    box-sizing: border-box;
  }
  .loved-by-founders-and-operator-parent {
    flex-wrap: wrap;
  }
  .event-dispatcher {
    min-width: 100%;
  }
  .resource-manager {
    flex-wrap: wrap;
  }
  .jobs-page-create {
    height: auto;
    min-width: 100%;
  }
  .divmx-auto {
    padding-top: 52px;
    padding-bottom: 52px;
    box-sizing: border-box;
  }
  .divw-layout-grid {
    flex-wrap: wrap;
    justify-content: center;
  }
  .divhero-section {
    height: auto;
    min-height: 889;
  }
  .divfooter-box,
  .divw-node-4a5aea71-b1c2-c09a,
  .divw-node-f4e37f78-482b-ff80- {
    min-height: auto;
  }
  .divw-layout-grid1 {
    flex-wrap: wrap;
  }
  .home {
    height: auto;
  }
}
@media screen and (max-width: 800px) {
  .ancestor-link {
    display: none;
  }
  .siblings-and-self {
    width: 307.3000000000002px;
  }
  .coding-speaks-louder {
    font-size: 58px;
    line-height: 58px;
    max-width: 100%;
  }
  .coding-speaks-louder-than-your-wrapper {
    padding-bottom: 327px;
    box-sizing: border-box;
  }

  .deserving-but-still-got-rejec-wrapper {
    padding-left: 39px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  .worry-not-jobify-container {
    font-size: 51px;
  }
  .jobify-is-a-container {
    font-size: 26px;
    line-height: 38px;
  }
  .ancestor-and-descendant {
    gap: 23px 94px;
  }
  .sibling-and-self {
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .divflex2 {
    flex-wrap: wrap;
  }
  .divtextbox {
    max-width: 100%;
  }
  .mixed-cluster {
    padding-left: 21px;
    padding-right: 21px;
    box-sizing: border-box;
  }
  .process-flow {
    flex-wrap: wrap;
  }
  .control-flow {
    height: auto;
  }
  .error-handler,
  .error-output,
  .error-prevention,
  .homeframe-parent {
    flex-wrap: wrap;
  }
  .ff41b3e9d8465bbeab4d9d-gridsv {
    gap: 32px 63.8px;
    max-width: 100%;
  }
  .divflex3,
  .divflex5,
  .dropdown-label-wrapper,
  .dropdown-label-wrapper1,
  .homeframe-parent3,
  .homeframe-parent4,
  .homeframe-parent5 {
    flex-wrap: wrap;
  }
  .ff41b3e9d8465bbeab4d9d-gridsv1 {
    height: auto;
    padding-top: 23px;
    padding-bottom: 30px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .connected-cluster {
    gap: 20px;
  }
  .to-desktop-handles-container {
    font-size: 51px;
    line-height: 58px;
  }
  .in-addition-to {
    max-width: 100%;
  }
  .to-desktop-handles-the-details-parent {
    gap: 16px 32px;
  }
  .divmx-0-parent,
  .homeframe-wrapper6 {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  .homeframe-section {
    gap: 23px 46px;
    padding-bottom: 46px;
    box-sizing: border-box;
  }
  .loved-by-founders {
    font-size: 51px;
    line-height: 48px;
  }
  .divflex11,
  .divflex13,
  .divflex15 {
    padding-top: 26px;
    padding-bottom: 26px;
    box-sizing: border-box;
  }
  .ready-for-revolution {
    max-width: 100%;
  }
  .use-our-bestest-container {
    font-size: 38px;
    line-height: 38px;
  }
  .todesktop-builder-will-container {
    max-width: 100%;
  }
  .homeframe-parent26 {
    flex-wrap: wrap;
  }
  .homeframe-parent25 {
    height: auto;
  }
  .homeframe-parent27,
  .homeframe-parent29,
  .sequence-connector {
    flex-wrap: wrap;
  }
  .jobs-page-create-inner {
    padding-left: 54px;
    box-sizing: border-box;
  }
  .jobs-page-create {
    padding-bottom: 183px;
    box-sizing: border-box;
  }
  .divrelative5 {
    gap: 87.5px 44px;
    padding-top: 32px;
    box-sizing: border-box;
  }
  .homeframe-parent16 {
    gap: 54px 109px;
  }
  .home-inner {
    padding-left: 39px;
    padding-right: 40px;
    padding-bottom: 58px;
    box-sizing: border-box;
  }
  .choose-a-plan-container {
    font-size: 38px;
    line-height: 48px;
  }
  .free {
    font-size: 29px;
    line-height: 32px;
  }
  .divrelative6 {
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;
  }
  .essential {
    font-size: 29px;
    line-height: 32px;
  }
  .divrelative7 {
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;
  }
  .professional {
    font-size: 29px;
    line-height: 32px;
  }
  .divrelative-parent {
    gap: 32px 16px;
  }
  .choose-a-plan-that-fits-your-n-parent {
    gap: 16px 32.5px;
  }
  .home-child {
    padding-left: 39px;
    padding-right: 40px;
    padding-bottom: 60px;
    box-sizing: border-box;
  }
  .faqs {
    font-size: 38px;
    line-height: 48px;
  }
  .can-i-try,
  .is-todesktop-for,
  .will-my-app {
    max-width: 100%;
  }
  .term-button2 {
    flex-wrap: wrap;
  }
  .which-version-of {
    max-width: 100%;
  }
  .term-button3 {
    flex-wrap: wrap;
  }
  .do-you-collect {
    max-width: 100%;
  }
  .term-button4 {
    flex-wrap: wrap;
  }
  .does-todesktop-work,
  .what-support-do {
    max-width: 100%;
  }
  .term-button6 {
    flex-wrap: wrap;
  }
  .what-features-are {
    max-width: 100%;
  }
  .term-button7 {
    flex-wrap: wrap;
  }
  .how-does-todesktop {
    max-width: 100%;
  }
  .term-button8 {
    flex-wrap: wrap;
  }
  .input-processor {
    width: 100%;
    height: 5px;
  }
  .how-does-my {
    max-width: 100%;
  }
  .term-button9 {
    flex-wrap: wrap;
  }
  .divmx-auto {
    gap: 16px 32px;
  }
  .divmx-auto-wrapper {
    padding-left: 39px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  .pmx-auto,
  .we-help-software {
    max-width: 100%;
  }
  .divcontainer {
    gap: 40px 80px;
  }
  .divfooter {
    padding-top: 52px;
    padding-bottom: 47px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .coding-speaks-louder {
    font-size: 43px;
    line-height: 43px;
  }
  .deserving-but-still {
    font-size: 22px;
  }
  .worry-not-jobify-container {
    font-size: 38px;
  }
  .jobify-is-a-container {
    font-size: 19px;
    line-height: 29px;
  }
  .whats-our-runway-container {
    font-size: 22px;
    line-height: 27px;
  }
  .divflex1 {
    flex-wrap: wrap;
  }
  .divtextbox {
    height: auto;
    padding-top: 36px;
    padding-bottom: 35px;
    box-sizing: border-box;
  }
  .data-processor,
  .data-transformer,
  .parallel-processing {
    flex: 1;
  }
  .design-system- {
    font-size: 16px;
  }
  .homeframe-group {
    flex-wrap: wrap;
  }
  .ff41b3e9d8465bbeab4d9d-gridsv {
    gap: 16px 63.8px;
  }
  .divw-node-1924c46e-32c9-1c4b {
    min-width: 100%;
  }
  .how-did-we-container {
    font-size: 22px;
    line-height: 27px;
  }
  .divflex4,
  .key-value-pair {
    flex-wrap: wrap;
  }
  .vuesaxlinearuser-square-parent {
    flex: 1;
  }
  .homeframe-div,
  .link-parent,
  .vuesaxlinearuser-square-container {
    flex-wrap: wrap;
  }
  .add-your-skills {
    font-size: 16px;
  }
  .error-handler1 {
    height: auto;
    min-height: 372;
  }
  .divw-node-1924c46e-32c9-1c4b1 {
    min-width: 100%;
  }
  .connected-cluster-wrapper {
    padding-bottom: 58px;
    box-sizing: border-box;
  }
  .to-desktop-handles-container {
    font-size: 38px;
    line-height: 43px;
  }
  .in-addition-to {
    font-size: 16px;
    line-height: 19px;
  }
  .monitored-and-recorded {
    font-size: 19px;
    line-height: 26px;
  }
  .homeframe-parent7,
  .image-18-parent {
    flex-wrap: wrap;
  }
  .focus-on-tech {
    font-size: 19px;
    line-height: 26px;
  }
  .divrelative1 {
    gap: 15px 30px;
  }
  .streamlined-interview-process {
    font-size: 19px;
    line-height: 26px;
  }
  .divm-1-group {
    flex-wrap: wrap;
  }
  .divrelative2 {
    gap: 23px 46px;
  }
  .customizable-look-and {
    font-size: 19px;
    line-height: 26px;
  }
  .divrelative3 {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .light-dark-themes {
    font-size: 19px;
    line-height: 26px;
  }
  .light-dark-themes-wrapper {
    padding-left: 20px;
  }
  .avatarssingle-parent,
  .divgroup2,
  .light-dark-themes-wrapper {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .avatarssingle-parent {
    flex-wrap: wrap;
  }
  .divgroup2 {
    padding-left: 20px;
  }
  .feedback-to-job {
    font-size: 19px;
    line-height: 26px;
  }
  .divrelative-inner1,
  .divrelative4 {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .divrelative4 {
    gap: 20px 41px;
  }
  .div1,
  .div2,
  .div3,
  .div4,
  .div5,
  .div6,
  .div7,
  .heading-2,
  .heading-21,
  .heading-22,
  .heading-23,
  .heading-24,
  .heading-25,
  .heading-26 {
    font-size: 19px;
    line-height: 22px;
  }
  .loved-by-founders {
    font-size: 38px;
    line-height: 36px;
  }
  .div8,
  .native-apis {
    font-size: 19px;
    line-height: 26px;
  }
  .div8 {
    line-height: 16px;
  }
  .branching-logic {
    flex-wrap: wrap;
  }
  .div9 {
    font-size: 19px;
    line-height: 16px;
  }
  .homeframe-parent18 {
    flex-wrap: wrap;
  }
  .homeframe-parent17 {
    gap: 19px 39px;
  }
  .divflex11 {
    gap: 39px 79px;
  }
  .cleaner-less-cluttered,
  .div10,
  .div11 {
    font-size: 19px;
    line-height: 26px;
  }
  .div10,
  .div11 {
    line-height: 16px;
  }
  .homeframe-parent20 {
    flex-wrap: wrap;
  }
  .homeframe-parent19 {
    gap: 19px 39px;
  }
  .divflex13 {
    gap: 67px 135px;
  }
  .code-optional,
  .div12,
  .div13 {
    font-size: 19px;
    line-height: 26px;
  }
  .div12,
  .div13 {
    line-height: 16px;
  }
  .homeframe-parent22 {
    flex-wrap: wrap;
  }
  .homeframe-parent21 {
    gap: 19px 39px;
  }
  .divflex15 {
    gap: 39px 79px;
  }
  .use-our-bestest-container {
    font-size: 29px;
    line-height: 29px;
  }
  .superscript {
    font-size: 19px;
    line-height: 26px;
  }
  .signal-processor {
    gap: 24px 49px;
  }
  .data-aggregator2 {
    flex-wrap: wrap;
  }
  .briefcase-parent,
  .buildings-parent,
  .iconsaxlinearmessagetext-parent {
    flex: 1;
  }
  .vuesaxlinearuser-square-parent4 {
    padding-right: 20px;
  }
  .jobs-page-create-inner,
  .vuesaxlinearuser-square-parent4 {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .divrelative5 {
    gap: 87.5px 22px;
  }
  .homeframe-parent16 {
    gap: 27px 109px;
  }
  .choose-a-plan-container {
    font-size: 29px;
    line-height: 36px;
  }
  .free {
    font-size: 22px;
    line-height: 24px;
  }
  .item,
  .item1,
  .item2,
  .item3 {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .divrelative6 {
    flex: 1;
  }
  .essential {
    font-size: 22px;
    line-height: 24px;
  }
  .div14 {
    font-size: 19px;
    line-height: 26px;
  }
  .item4 {
    flex-wrap: wrap;
  }
  .item4,
  .item5,
  .item6,
  .item7 {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .divrelative7 {
    gap: 20px 40px;
    flex: 1;
  }
  .professional {
    font-size: 22px;
    line-height: 24px;
  }
  .div15 {
    font-size: 19px;
    line-height: 26px;
  }
  .item8 {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .item9 {
    flex-wrap: wrap;
  }
  .item10,
  .item11,
  .item9 {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .item11 {
    flex-wrap: wrap;
  }
  .divrelative8 {
    gap: 20px 40px;
  }
  .divrelative-group {
    height: auto;
    min-height: 545;
  }
  .home-child {
    padding-bottom: 39px;
    box-sizing: border-box;
  }
  .faqs {
    font-size: 29px;
    line-height: 36px;
  }
  .term-button,
  .term-button1,
  .term-button5 {
    flex-wrap: wrap;
  }
  .divmx-auto {
    padding-top: 34px;
    padding-bottom: 34px;
    box-sizing: border-box;
  }
  .we-help-software {
    font-size: 16px;
    line-height: 19px;
  }
  .divflex-parent1 {
    flex-wrap: wrap;
  }
  .jobify6 {
    font-size: 16px;
    line-height: 22px;
  }
  .divcopyright-social-div {
    flex-wrap: wrap;
  }
  .divcontainer {
    gap: 20px 80px;
  }
}
