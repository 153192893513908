.job-list-body {
    background-color: #C8D0D980;
}


.filters-main-box {
    position: fixed;
}


.f-head {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    margin-bottom: 16px;
}

label.form-check-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: var(--secondary-text-color) !important;
}

.job-loc {
    margin-left: 5px;
    color: #64748B;
}

.job-chips {
    display: flex;
    gap: 10px;
    /* margin-top: 10px; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.ml-2 {
    margin-left: 8px;
}

.mr-2 {
    margin-right: 8px;
}

.job-chip {
    background-color: #EBECF0;
    color: var(--tertiary-text-color);
    border-radius: 5px;
    padding: 2px 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    text-transform: capitalize;
}

.jobs-container {
    display: flex;
    gap: 20px;
    flex: 1;
    width: 90%;
    margin: auto;
    padding: 20px 0px;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        padding: 20px 20px;
    }

    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.filter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media only screen and (max-width: 767px) {
        width: 100%;
        flex-direction: row;
    }

    @media only screen and (min-width: 768px) {
        width: 33%;
    }

    @media only screen and (min-width: 1024px) {
        width: 25%;
    }

    @media only screen and (min-width: 1240px) {
        width: 20%;
    }

    @media only screen and (min-width: 1540px) {
        width: 15%;
    }
}

.filter-box {
    padding: 16px;
    border-radius: 16px;
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color) !important;

    @media only screen and (max-width: 767px) {
        flex: 1;
    }
}

.job-card {
    gap: 12px;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color) !important;
}
