.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #111a19;
  color: #64748b;
}
.frame {
  background-color: #131514;
  border-radius: 16px;
  box-shadow: var(--shadow-1);
  position: relative;
  padding: 20px !important;
  width: 480px;
}

.frame input:focus {
  border-color: #b4ff45 !important;
}

.login-box {
  width: 440px;
  @media (max-width: 480px) {
    width: 95%;
  }
}

.frame .div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin: auto;
}

.frame .text-wrapper {
  color: var(--colors-neutral-greys-800);
  font-family: var(--h5-semibold-font-family);
  font-size: var(--h5-semibold-font-size);
  font-style: var(--h5-semibold-font-style);
  font-weight: var(--h5-semibold-font-weight);
  letter-spacing: var(--h5-semibold-letter-spacing);
  line-height: var(--h5-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .dropdown-label {
  display: flex;
  height: 30px;
  padding: 24px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  text-align: center;
  opacity: 0.4;
  background: var(--primary-main-500, #c5c9ce);
  transition: all 0.3s;
}
.frame .dropdown-label.active {
  background-color: var(--colors-primary-main-500);
  border-radius: 8px;
  opacity: 0.4;
}

.frame .dropdown-label + .dropdown-label {
  margin-left: 10px;
}
.frame .frame-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  margin-bottom: -0.5px;
  margin-top: -0.5px;
  position: relative;
}

.frame .placeholder-2 {
  color: var(--colors-neutral-greys-500);
  font-family: var(--b3-regular-font-family);
  font-size: var(--b3-regular-font-size);
  font-style: var(--b3-regular-font-style);
  font-weight: var(--b3-regular-font-weight);
  letter-spacing: var(--b3-regular-letter-spacing);
  line-height: var(--b3-regular-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-2 {
  align-self: stretch;
  color: var(--colors-neutral-greys-500);
  font-family: var(--b3-semibold-font-family);
  font-size: var(--b3-semibold-font-size);
  font-style: var(--b3-semibold-font-style);
  font-weight: var(--b3-semibold-font-weight);
  letter-spacing: var(--b3-semibold-letter-spacing);
  line-height: var(--b3-semibold-line-height);
  margin-top: -1px;
  position: relative;
}
.frame .div-4 input {
  width: 21.75rem;
  padding: 8px;
  border: 1px solid var(--colors-neutral-greys-200);
  border-radius: 6px;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #e2f0e7 !important;
}
.frame .div-4 input.error {
  border-color: red;
}

.frame .div-4 .text-wrapper-3 {
  color: red;
  font-size: 14px;
  margin-top: 8px;
}

.frame .text-wrapper-3 {
  color: var(--colors-neutral-greys-400);
  flex: 1;
  font-family: var(--b3-regular-font-family);
  font-size: var(--b3-regular-font-size);
  font-style: var(--b3-regular-font-style);
  font-weight: var(--b3-regular-font-weight);
  height: 20px;
  letter-spacing: var(--b3-regular-letter-spacing);
  line-height: var(--b3-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.frame .link button {
  border-radius: 8px;
  font-size: 14px;
  background-color: #b4ff45;
  border: none;
  padding: 8px;
  width: 21.75rem;
  margin-left: 0;
  justify-content: center;
}

.frame .text-wrapper-4 {
  color: var(--colors-neutral-greys-0);
  font-family: "Roboto-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

nav{
  background-color: transparent !important;
  padding: 0 !important;
}
.nav-item .nav-link.active {
  /* background-color:var(--primary-main-500, #aab7f0); */
  color: var(--colors-neutral-greys-0);
  background-color: #2563eb;
  font-weight: bold;
}

.nav-item .nav-link {
  color: black;
  font-weight: normal;
  gap: 50px;
  padding: 7px 10px;
}

.custom-tabs {
  display: flex;
  width: 100%;
}

.custom-tab {
  padding: 5px 0px;
  color: #868c8a !important;
  text-align: center;
  text-decoration: none;
  flex: 1;
  font-size: 14px;
}

.nav-link {
  color: #868c8a !important;
}

.custom-tab.active {
  background: white;
  color: black !important;
}

.jobify-image {
  position: absolute;
  width: 100px;
  left: 0px;
  top: -38px;
}

.contact-us-text {
  color: #b4ff45;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .login-container {
    padding: 1rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-box {
    width: 100%;
    max-width: 400px;
    padding: 1.5rem;
  }

  .frame {
    width: 100%;
  }

  .jobify-image {
    margin-bottom: 2rem;
  }

  .custom-tabs {
    width: 100%;
  }

  .custom-tab {
    font-size: 14px;
    padding: 8px 16px;
  }

  input[type="email"] {
    width: 100%;
    font-size: 14px;
    padding: 12px;
  }

  .text-wrapper-4 {
    width: 100%;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .login-box {
    padding: 1rem;
  }

  .text-wrapper {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .custom-tab {
    font-size: 13px;
    padding: 6px 12px;
  }

  .text-wrapper-2 {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .contact-us-text {
    font-size: 14px;
  }
}
