.admin-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
  color: #fff;
  width: 80%;
}

.main-section {
  background-color: #131514;
}

.email-input-container {
  margin-bottom: 20px;
}

.test-details-text {
  font-size: 16px;
  font-weight: 600;
  color: #e2f0e7;
}

.recruiter-email {
  width: 100%;
  padding: 12px;
  background-color: transparent;
  border: 1px solid #f8fcfa !important;
  border-radius: 8px;
  color: #868c8a;
  font-size: 16px;
}

.tabs-container {
  display: flex;
  gap: 0;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  padding: 8px;
  height: 48px;
  /* border-radius: 4px; */
}

.admin-tab {
  background: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.3s ease;
  position: relative;
  border-bottom: 1px solid grey;
  width: 100%;
  justify-content: center;
  border-radius: 0;
}

.admin-tab:not(:last-child)::after {
  content: "›";
  position: absolute;
  right: -4px;
  color: #666;
  font-size: 20px;
}

.admin-tab:hover {
  color: white;
}

.admin-tab.active {
  color: white;
  border-bottom: 2px solid #b4ff45;
}

.tab-icon {
  font-size: 18px;
}

.test-details {
  padding: 10px 20px;
}

.test-details h2 {
  margin-bottom: 20px;
  font-weight: normal;
  color: #fff;
}

.test-inputs {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group label {
  color: #868c8a !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.test-title {
  padding: 12px !important;
  background-color: transparent !important;
  border: 1px solid #333 !important;
  border-radius: 16px !important;
  color: white !important;
  font-size: 16px !important;
}

.test-duration {
  border-radius: 8px !important;
}

.add-question-btn {
  background: transparent;
  border: none;
  color: #b4ff45;
  cursor: pointer;
  padding: 8px 0;
  font-size: 16px;
  text-align: left;
}

.add-question-btn:hover {
  text-decoration: underline;
}

input:focus {
  outline: none;
  border-color: #4caf50;
}

::placeholder {
  color: #ccc !important;
}

.question-item {
  padding: 12px 16px;
  display: flex;
  /* height: 41px; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2d2d2d;
  border-radius: 10px;
  width: 95%;
}

.questions-list-home {
  overflow-y: auto;
  margin-top: 8px;
}

.priority-btn {
  background-color: #131514 !important;
  height: 49px !important;
  margin-top: 20px;
  border: solid 1px #2d2e2e;

  &:hover {
    background-color: #2d2e2e !important;
  }

  &:focus {
    border-color: #b4ff45 !important;
  }
}

.priority-btn.active {
  background-color: #4caf50;
  color: white;
}

.error-message {
  color: #ff4444;
  font-size: 14px;
  margin-top: 4px;
  display: block;
}
