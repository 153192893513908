// @import "../typography.scss";

.test-header {
  height: 52px;
  // margin: 14px 20px;
  padding: 0px 16px;
  margin: 10px;
  border: 1px solid #404342;
  border-radius: 14px;
  background-color: var(--Neutral-Greys-50, #191c1b);
  width: auto;
  .view-all-btn {
    display: flex;
    align-items: center;
    gap: 6px;

    .viewAllQuestionsIcon {
      width: 24px;
      height: 24px;
    }
  }

  .test-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    .test-pagination-btn-group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      .test-pagination-btn {
        display: flex;
        align-items: center;

        &.active {
          border: solid #b4ff45 1px;
        }
      }
    }

    .test-pagination-arrow-btn {
      border: 1px solid var(--Neutral-Greys-950, #f8fafc);
    }

    button {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #191c1b;
      width: 32px;
      height: 33px;
      color: white;
      border-radius: 8px;
      background-color: transparent;
    }
  }

  .testTimerEnd {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .timerClock {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 16.94px;
      color: #60a5fa;

      .clockIcon {
        width: 24px;
        height: 24px;
      }
    }

    .testStopBtn {
      // @include regularB3;
      color: #ef4444;
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: transparent;

      .testStopIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}