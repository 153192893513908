.tag {
    background-color: #161616;
    color: rgb(122, 122, 122);
    border-radius: 14px;
    padding: 5px 10px;
    font-size: 1.1rem;
    display: inline-block;
    margin: 5px;
    padding:16px 20px;
  }
  
  