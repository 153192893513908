
/* Input styles starts */

.input-label {
  font-size: 14px;
  font-family: "Neue Haas Unica Pro", sans-serif;
  font-weight: 400;
  color: #323232;
}

// .form-control {
//   display: block;
//   width: 100%;
//   border: 1px solid var(--primary-border-color);
//   border-radius: 8px;
//   padding: 15.5px 12px;
//   outline: none;

//   font-size: 14px;
//   color: var(--primary-text-color) !important;
//   background: transparent !important;
//   line-height: 14px;

//   &:focus {
//     border-color: var(--secondary-border-color);
//     box-shadow: none !important;
//   }
// }

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select {
  padding: 0px 12px;
  width: 100%;
  height: 50px;
  background: transparent;
  color: var(--primary-text-color);
  border: 1px solid var(--primary-border-color);
  border-radius: 8px;
  font-family: "Neue Haas Unica Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

input:focus,
select:focus {
  border-color: var(--secondary-border-color) !important;
  box-shadow: none !important;
}

input:focus-visible,
select:focus-visible {
  border-color: var(--secondary-border-color) !important;
  box-shadow: none !important;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-weight: 400;
  opacity: 1;
}

input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled {
  opacity: .5
}

.invalid {
  border-color: #ef4444 !important; /* @apply tw-border-red-500; */
}

.error {
  @apply tw-text-red-500 tw-capitalize;
}

.required::after {
  content: ' *';
  @apply tw-text-red-500;
}

/* Input styles ends */