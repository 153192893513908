.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
  color: #fff;
}

.questionBankheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  padding:0
}

.questionBankheader h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.createButton {
  background-color: transparent;
  color: #4ade80;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: opacity 0.2s;
}

.createButton:hover {
  opacity: 0.8;
}

.listContainer {
  /* background-color: #262626; */
  border-radius: 8px;
  overflow: hidden;
}

.listHeader {
  display: flex;
  padding: 1rem 2rem;
  /* background-color: #333; */
  color: #999;
  font-size: 0.875rem;
  align-items: center;
}

.titleHeader {
  flex: 1;
}

.title {
  color: #9b9e9d;
}

.typeHeader {
  width: 210px;
  display: flex;
  align-items: center;
  /* gap: 0.5rem; */
}

.arrow {
  margin-left: 4px;
}

.listItem {
  display: flex;
  background-color: #2d2e2e;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 10px;
  height: 41px;
  margin-bottom: 10px;
  font-weight: 600;
}

.listItem:hover {
  background-color: #303030;
}

.questionInfo {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.number {
  color: #666;
}

.type {
  width: 200px;
  color: #868c8a;
  font-weight: 600;
}

.viewButton {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: opacity 0.2s;
}

.viewButton:hover {
  opacity: 0.8;
}

.questionBankPagination {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.questionBankPagination button {
  width: 32px;
  height: 32px;
  background-color: #191c1b;
  color: #9b9e9d;
  border-radius: 8px;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.questionBankPagination button:disabled {
  opacity: 40%;
  cursor: not-allowed;
}

.paginationNoBtns,
.paginationNextPrevBtn {
  display: flex;
  gap: 6px;
}

.paginationNextPrevBtn button {
  border: 1px solid #f8fafc;
}

.paginationNoBtns .active {
  color: #eff6ff;
  border: 1px solid #b4ff45;
}
