.resume_upload_container {
  @apply tw-h-full;
  @extend .tw-flex-middle;

  .input_form {
    @apply tw-w-11/12 sm:tw-w-[480px] tw-p-4 tw-rounded-xl;
    background: var(--secondary-background-color);
    box-shadow: 0px 4px 20px 0px #09131514;

    .sub-heading {
      @apply tw-text-sm;
      color: var(--tertiary-text-color);
    }

    .get_otp {
      @extend .sub-heading;
      @apply tw-ml-2 tw-mt-1 tw-cursor-pointer hover:tw-text-white;
    }

    .file_upload {
      width: 100%;
      background: var(--shadow);
      color: var(--tertiary-text-color); // url('../../assets/images/upload-arrow.png') no-repeat center
      border-radius: 8px;
      padding: 120px 0 0 0;
      height: 100px;
      overflow: hidden;
      cursor: pointer;
      &::after {
        content: 'Upload file';
        background: url('../../assets/images/upload-arrow.png') no-repeat center left;
        padding-left: 25px;

        position: absolute;
        inset: 0;
        margin: auto;
        height: 20px;
        width: 125px;
      }
    }

    .uploaded_file {
      width: 100%;
      height: 60px;
      background: var(--shadow);
      color: var(--tertiary-text-color);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
    }
  }
}

.tw-flex-middle {
  @apply tw-flex tw-items-center tw-justify-center;
}