// @use "../typography.scss";

.viewAllQuestionPage {
  height:inherit;
  background-color: black;

  .viewAllQestionPageHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #272526;
    height: 50px;
    border-radius: 8px;
    margin-top: 80px;

    .timerClock {
      background-color: #431407;
      height: 30px;
      border-radius: 8px;
      width: max-content;
      padding: 8px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
  }

  .test-listing-page-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;

    .questionTypeSelect,
    .questionStatusSelect {
      .dropdown-menu {
        &.show {
          width: 250px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          background-color: black;
          color: #868c8a;

          .dropdown-item {
            height: 40px;
            padding: 12px;

            &:hover {
              background-color: #191c1b;
              color: #868c8a;
            }
          }
        }
      }
    }

    .test-segregation-box {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .code-test-card {
        background-color: #2d2e2e;
        align-items: center;
        padding: 16px;
        border-radius: 16px;

        .test-page-action-btn {
          padding: 12px 16px;
          display: flex;
          align-items: center;
          gap: 4px;

          .solvedTickIcon {
            width: 16px;
            height: 16px;
            fill: #868c8a;
          }
        }
      }
    }

    .viewAllQuestionPagePagination {
      display: flex;
      justify-content: space-between;

      .paginationNoBtns,
      .paginationNextPrevBtn {
        display: flex;
        gap: 6px;
      }

      .paginationNextPrevBtn {
        button {
          border: 1px solid #f8fafc;
        }
      }

      .paginationNoBtns {
        .active {
          color: #eff6ff;
          border: 1px solid #b4ff45;
        }
      }

      button {
        width: 32px;
        height: 32px;
        background-color: #191c1b;
        color: #9b9e9d;
        border-radius: 8px;
        padding: 8px 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          opacity: 40%;
        }
      }
    }
  }
  .footerBar {
    background-color: #191c1b;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: max-content;
      height: 40px;
      background-color: #3b82f6;
      color: #f8fcfa;
      // @include regularB3;
    }
  }
}
