.container {
  /* background-color: black; */
  color: white;
  padding: 1rem;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.cardGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 0 1rem;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 1rem;
  gap: 0.5rem;
}

@media screen and (min-width: 768px) {
  .cardGrid {
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }

  .container {
    padding: 2rem;
  }

  .tagContainer {
    padding: 0;
  }
}
