body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.landing-page {
  /* background: #0D0D0D; /* Fallback for browsers that don't support gradients */
  background: linear-gradient(to bottom, #0d0d0d, #141616);
  color: #d6d6d6;
  display: flex;
  flex-direction: column;
}

.terms-conitions.landing-page {
  height: 100%
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.icon {
  background-color: #ffffff;
  color: #000000;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
}

nav {
  background-color: #161616;
  color: rgb(122, 122, 122);
  padding: 16px 20px;
  border-radius: 50px;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #ffffff !important;
  text-decoration: none !important;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

nav ul li a:hover {
  opacity: 1;
}

.signup-btn {
  background-color: #bffd56;
  color: #000000;
  border: none;
  padding: 16px 28px;
  gap: 10px;
  border-radius: 20px;
  height: 50px;
  cursor: pointer;
  /* font-weight: bold; */

  &:hover {
    color: #ffffff !important;
  }
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
}

.hero {
  margin-top: 140px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 134px;
  font-family: sans-serif;
  margin-bottom: 20px;
  font-weight: bold;
}

h1 span {
  display: block;
}

.hero p {
  font-size: 18px;
  opacity: 0.7;
  margin-bottom: 30px;
  color: rgb(125, 127, 120);
}

.try-demo-btn {
  background-color: #b8ff00;
  color: #000000;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.introduction {
  max-width: 800px;
}

.intro-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.intro-icon {
  color: rgb(191, 253, 86);
  margin-right: 10px;
}

.intro-text {
  color: rgb(191, 253, 86);
}

h2 {
  font-size: 36px;
  font-weight: normal;
  line-height: 1.4;
  opacity: 0.9;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;
  width: 100%;
  max-width: 1000px;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 30px;
  text-align: left;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 32px;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #bffd56;
}

.feature-card p {
  font-size: 16px;
  line-height: 1.6;
  opacity: 0.7;
  margin: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  color: #fff;
  margin-bottom: 20px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.footer-links a:hover {
  opacity: 1;
}

.footer-made-with {
  opacity: 0.7;
}

.footer-made-with .heart {
  color: #fff;
  margin: 0 4px;
}

.heart {
  color: red;
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 15px 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
  }

  nav {
    order: 3;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
  }

  .signup-btn {
    order: 2;
    margin-left: auto;
  }

  .faq-section {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .faq-item {
    width: 100%;
    margin-bottom: 15px;
  }

  .faq-question {
    font-size: 16px;
    padding: 15px;
  }

  .faq-answer {
    font-size: 14px;
    padding: 15px;
  }

  nav ul {
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  nav ul li {
    margin: 0 10px;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 24px;
  }

  .hero {
    margin-top: 180px;
  }

  .features-grid {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  main {
    padding: 20px;
  }

  .introduction {
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  header {
    padding: 10px 15px;
  }

  .signup-btn {
    padding: 10px 20px;
    font-size: 14px;
    height: auto;
  }

  .faq-question {
    font-size: 12px;
    padding: 12px;
  }

  .faq-answer {
    font-size: 13px;
    padding: 12px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 20px;
  }

  .hero {
    margin-top: 160px;
    margin-bottom: 60px;
  }

  .footer {
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 20px;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .footer-links a {
    margin-right: 0;
  }
}

/* Add these styles for the mobile menu button */
.mobile-menu-btn {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.mobile-menu-btn span {
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .mobile-menu-btn {
    display: flex;
  }

  nav {
    display: none;
    position: fixed;
    top: 35px;
    left: 0;
    right: 0;
    background-color: #161616 !important;
    padding: 20px;
    border-radius: 0;
  }

  nav.mobile-menu-open {
    display: block;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 15px 0;
  }
}
