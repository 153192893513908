body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
html {
  height: 100%;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ReactModal__Overlay--after-open {
  z-index: 100 !important;
}
/* react-modal css overriding */
.feedback-modal {
  width: 50rem !important;
}

@media only screen and (max-width: 768px) {
  .feedback-modal {
    width: 90% !important;
    min-height: 90% !important;
  }
}

.jobify-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: var(--shadow);
  z-index: 100;
}

.jobify-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  width: 900px;

  background-color: var(--secondary-background-color);
  color: var(--primary-text-color);
}

.jobify-modal-content .content {
  max-height: 85vh;
  overflow-y: auto;
}

/* Overridding react-tabs styles */
.react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 !important;
  border-bottom: solid 2px rgba(128, 128, 128, 0.177) !important;
}
.react-tabs__tab-panel--selected.recruiters-view-job-creation {
  padding: 20px 0px !important;
  height: calc(100vh - 240px);
}

.react-tabs__tab-panel--selected.recruiters-view-active-jobs {
  padding: 20px 0px !important;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.react-tabs__tab--selected {
  background-color: #3b83f6cf !important;
  color: white !important;
  /* font-size: 20px; */
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  /* padding:  10px !important; */
}

input.company_logo_upload#file {
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    center center no-repeat var(--shadow);
  border-radius: 20px;
  background-size: 60px 60px;
}

.cam-video {
  position: fixed;
  bottom: 100px;
  right: 20px;
  height: 250px;
  width: 250px;
  z-index: 9;
}

.flex-1 {
  flex: 1;
}

.hide {
  display: none;
}

.theme-dark .rte-editor pre {
  background-color: #ffffff12;
  border-color: #f7faff1f;
  color: #eff1f6bf;
}

.theme-dark .rte-editor code {
  background-color: #ffffff12;
  border-color: #f7faff1f;
  color: #eff1f6bf;
}

.recruiters-view {
  /* height: calc(100vh - 70px); */
  /* overflow-y: scroll; */
}

.btn {
  font-size: 14px !important;
}

.theme-light .btn-sub {
  /* padding: 9px 12px; */
  background-color: #ffffff !important;
  color: #1e293b !important;
  font-weight: 600;
  border: 1px solid #e2e8f0 !important;
}

.theme-light .btn-main {
  /* padding: 9px 32px; */
  background-color: #3b82f6 !important;
  color: #ffffff !important;
  font-weight: 600;
}

.theme-dark .btn-sub {
  /* padding: 9px 12px; */
  background-color: #393a3a !important;
  color: #f8fcfa !important;
  font-weight: 600;
  border: 1px solid #2d2e2e !important;
}

.theme-dark .btn-main {
  /* padding: 9px 32px; */
  background-color: #b4ff45 !important;
  color: #131514 !important;
  font-weight: 600;
}

/**
  * Custom CSS for the react-draft WYSIWYG editor
  */

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: 90% !important;
}

.rdw-editor-main {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;

  background-color: var(--secondary-background-color) !important;
  color: var(--primary-text-color) !important;
}

.rdw-editor-main pre {
  margin: 0 !important;
}

.rdw-editor-main.edit-box {
  height: 380px !important;
}

.rdw-editor-main.editor-readonly {
  height: 100% !important;
  border: none !important;
}

.theme-dark .rdw-editor-main pre {
  background-color: #3a3838;
  color: var(--primary-text-color) !important;
}

/* .theme-light .rdw-editor-main {
    background-color: var(--secondary-background-color) !important;
    color: var(--primary-text-color) !important;
  }

  .theme-dark .rdw-editor-main {
    background-color: var(--secondary-background-color) !important;
    color: var(--primary-text-color) !important;
  } */
