.main-container {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  & .question-section {

    width: 60%;

    & .filter-container {
      display: flex;
      justify-content: center;
      // margin-top: 1rem;
      width: 100%;
      align-items: center;

      & .filter-section {
        display: flex;
        width: 100%;

        & .question-type {
          margin-top: 10px;
          border: none;
          background-color: transparent;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;

          button {
            color: var(--Neutral-Greys-500, #868C8A);
            background-color: transparent;
            padding: 0;
          }
        }
        & .dropdown-menu {
          width: 200px;
          &.show {
            display: flex;
            flex-direction: column;
            background-color: black;
            color: #868c8a;
            height: auto;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 8px;
            }

            &::-webkit-scrollbar-track {
              background: #191C1B;
              border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb {
              background: #404342;
              border-radius: 4px;

              &:hover {
                background: #868C8A;
              }
            }

            .dropdown-item {
              height: 40px;
              padding: 12px;

              &:hover {
                background-color: #191c1b;
                color: #868c8a;
              }
            }
          }
        }
      }

      & .rte-main-container {
        // width: 60%;
        background-color: #131514;
        border: none;
        padding: 20px;
        border-radius: 16px;

        & .title {
          font-size: 16px;
          color: #E2F0E7;
          font-weight: 600;
          margin-bottom: 10px;
          margin: 0
        }
        & .description {
          font-size: 14px;
          color: #868C8A
        }
        & .rte-section {
          // border: solid 1px #2D2E2E;
          border-radius: 8px;
          & .editor-container {
            height: 400px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 8px;
            }

            &::-webkit-scrollbar-track {
              background: #191C1B;
              border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb {
              background: #404342;
              border-radius: 4px;

              &:hover {
                background: #868C8A;
              }
            }

            & .public-DraftEditor-content {
              min-height: 400px;
            }
            margin-top: 10px;
            min-height: 0;
            span {
              color: #E2F0E7;
            }

            & .form-control {
              background-color: #1A1A1A;
              border: 1px solid #333333;
              color: #E2F0E7;
              padding: 0.5rem;
              border-radius: 4px;

              &:focus {
                background-color: #1A1A1A;
                border-color: #4CAF50;
                color: #E2F0E7;
                box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
              }

              &::placeholder {

                color: #666666;
              }
            }
          }
        }
        & .rdw-editor-main {
          border-radius: 16px !important;
          border: none !important;

        }

        & .form-control {
          margin-top: 10px;
        }
        & .rdw-editor-wrapper {
          display: flex;
          flex-direction: column-reverse;
        }

        .mb-4 {
          margin-bottom: 1rem;
        }

        .form-control {
          background-color: #1A1A1A;
          border: 1px solid #333333;
          color: #E2F0E7;
          padding: 0.5rem;
          border-radius: 4px;

          &:focus {
            background-color: #1A1A1A;
            border-color: #4CAF50;
            color: #E2F0E7;
            box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
          }

          &::placeholder {
            color: #666666;
          }
        }
      }

      & .mcq-container {

        & .rte-section {
          & .editor-container {
            height: 100px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 8px;
            }

            &::-webkit-scrollbar-track {
              background: #191C1B;
              border-radius: 4px;
            }
            &::-webkit-scrollbar-thumb {
              background: #404342;
              border-radius: 4px;

              &:hover {
                background: #868C8A;
              }
            }
            & .public-DraftEditor-content {
              
            }

            margin-top: 10px;


          }
        }
        & .mcq-rte-section {
          height: 218px;
        }
      }
    }
  }

  .questions-form-footer {
    bottom: 10px;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    z-index: 99;
    & .update-button {
      background-color: transparent !important;
      color: #B4FF45 !important;
      border: solid 1px #B4FF45;
      width: 82px;
      height: 41px;
      &:hover {
        background-color: #444444 !important;
      }
    }
    & .save-button {
      width: 65px;
      height: 41px;
      &:hover {
        background-color: #9FE53C !important;
        color: #000 !important;
      }
    }
    & .back-button {
      width: 65px;
      height: 41px;
    }
    & .questions-footer {
      border: 1px solid var(--primary-border-color);
      padding: 16px;
      border-radius: 8px;
      display: flex;
      justify-content: flex-end;
      box-shadow: 0px 0px 10px rgba(92, 92, 92, 0.45);
      background: var(--secondary-background-color);
    }
  }

  .mcq-options-container {
    margin-top: 2rem;

    .title-row {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;

      .mcq-question-title {
        font-size: 16px;
        font-weight: 600;
        color: #E2F0E7;
      }

      .subtitle {
        font-size: 14px;
        color: #888;
      }
    }

    .option-row {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      .option-checkbox {
        margin: 0;
        width: 20px;
        height: 20px;
        border-radius: 5px !important;
        position: relative;
        appearance: none;
        border: 1px solid #3D3D3D;
        background-color: transparent;

        &[type="checkbox"] {
          &:checked {
            background-color: #B4FF45;
            border-color: #B4FF45;

            &::after {
              content: '';
              position: absolute;
              left: 6px;
              top: 6px;
              width: 10px;
              height: 6px;
              border: solid #000;
              border-width: 0 0 2px 2px;
              transform: rotate(-40deg);
            }
          }
        }
      }

      .option-input {
        flex: 1;
        background: #2D2E2E !important;
        border: 1px solid #3D3D3D;
        color: #F8FCFA !important;
        padding: 8px 12px;
        border-radius: 12px;
        height: 44px;
        width: 100%;
        &::placeholder {
          font-weight: 600;
        }
        &:focus {
          border-color: #4CAF50;
          box-shadow: none;
        }
      }

      .option-delete {
        background: none;
        border: none;
        padding: 4px;
        cursor: pointer;

        &:hover {
          opacity: 1;
          transform: scale(1.1);
          transition: all 0.2s ease-in-out;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .add-option-btn {
      background: none;
      border: none;
      color: #B4FF45;
      padding: 8px 0;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .points-input {
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      width: 80px;
      padding: 4px 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .mb-4 {
    margin-bottom: 1rem;

    .question-title {
      display: block;
      margin-bottom: 0.5rem;
    }

    input[type="number"] {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }
  }
}