.legal-docs-container {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .tabs-section {
    width: 60%;

    background: #1E1E1E;
    border-radius: 8px;
    min-height: 500px;

    .tabs-container {
      display: flex;
      gap: 20px;
      padding: 20px 20px 0;
      border-bottom: 1px solid #333;

      .admin-tab {
        padding: 10px 20px;
        color: #E2F0E7;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        border-bottom: 2px solid transparent;

        &.active {
          color: white;
          border-bottom: 2px solid #b4ff45;
        }

        .tab-icon {
          font-size: 16px;
        }
      }
    }

    .content-section {
      padding: 20px;

      .rte-section {
        height: 600px;
        margin-bottom: 20px;
        // min-height: 400px;

        & .editor-container {
          height: 550px;
          overflow-y: auto;
        }
      }
    }
  }

  .form-footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;

    .btn-main {
      background: #00875A;
      color: white;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;

      &:hover {
        background: #006644;
      }
    }
  }
}