.theme-light {
    --primary-text-color: #1E293B;
    --secondary-text-color: #475569;
    --tertiary-text-color: #64748B;

    --placeholder-color: #94A3B8;

    --primary-border-color: #E2E8F0;
    --secondary-border-color: #3B82F6;

    --accent: #dbe7e4;
    --button-border: #5E4B56;
    --primary-background-color: #F1F5F9;
    --secondary-background-color: #ffffff;
    --light-background: #EDDCD2;
    --shadow: rgba(206, 206, 206, 0.75);

    /* --light-text: #5E4B56;
    --dark-text: #ff0095; */
}

.theme-dark {
    --primary-text-color: #E2F0E7;
    --secondary-text-color: #9B9E9D;
    --tertiary-text-color: #868C8A;

    --placeholder-color: #404342;


    --primary-border-color: #2D2E2E;
    --secondary-border-color: #B4FF45;

    --primary-background-color: #111A19;
    --secondary-background-color: #131514;
    --light-background: #586F7C;
    --accent: #b8dbd9;
    --button-border: #b8dbd9;
    --shadow: rgba(65, 65, 65, 0.75);

    /* --light-text: #F9F8F8;
    --dark-text: #292929; */
}

div.app-box {
    background-color: var(--primary-background-color);
    color: var(--primary-text-color) !important;
    overflow-y: auto;
}

label {
    color: var(--primary-text-color) !important;
}

h5 {
    color: var(--primary-text-color) !important;
}

.shadow-box {
    box-shadow: 5px 5px 10px 0px var(--shadow);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}