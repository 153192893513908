/* .form-control {
  padding: 0px !important;
} */
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #111a19;
  color: #e2e8f0;
}

.login-box {
  background: transparent;
  padding: 2rem;
  width: 100%;
  max-width: 480px;
}

.otp-frame {
  background-color: #131514;
  border-radius: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  padding: 24px;
  width: 480px;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right:20px
}

.otp-jobify-image {
  width: 278px;
  height: 29px;
  left: 0px;
  top: -38px;
}

.otp-jobify-image img {
  width: 278px;
  height: auto;
  margin-bottom: 10px;
}

.form-control {
  width: 40px;
  height: 40px;
  border: 1px solid #2d3748;
  background: #1a202c;
  color: #e2e8f0;
  font-size: 18px;
  border-radius: 8px;
}

.form-control:focus {
  border-color: #3b82f6;
  box-shadow: none;
  background: #1a202c;
}

a {
  color: #3b82f6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.btn-primary {
  border-radius: 8px;
  font-size: 14px;
  background-color: #b4ff45;
  border: none;
  padding: 8px;
  width: 21.75rem;
  color: #131514;
  font-weight: 600;
  height: 52px;
  width: 448px;
}

.otp-continue:disabled {
  background: #b4ff45 !important;
  cursor: not-allowed;
  opacity: 20%;
}

.text-muted {
  color: #868c8a !important;
  display: flex;
  flex-wrap: wrap;
}

.text-muted a.policy-link {
  display: inline;
  text-decoration: none;
}

.text-muted a.policy-link:hover {
  text-decoration: underline;
}

p {
  color: #64748b;
  font-size: 14px;
}

b {
  color: #e2e8f0;
  font-size: 18px;
}

.verification-code {
  color: #64748b;
  font-size: 14px;
}

.otp-header {
  text-align: left;
  padding-left: 8px;
  display: flex;
  /* justify-content: space-between; */
}

.otp-header b {
  display: block;
  margin-bottom: 8px;
}

.otp-header span {
  display: block;
}

.email-text,
.policy-link {
  color: #b4ff45 !important;
  font-weight: 400;
  display: inline !important;
}

.otp-frame .link button {
  border-radius: 8px;
  font-size: 14px;
  background-color: #b4ff45;
  border: none;
  padding: 8px;
  width: 21.75rem;
  justify-content: center;
}

.otp-frame input {
  width: 21.75rem;
  padding: 8px;
  border: solid 1px #2d2e2e !important;
  border-radius: 6px;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #e2f0e7 !important;
}

.otp-frame input:focus {
  border-color: #b4ff45 !important;
}
