.card {
  background-color: #1c1c1c;
  color: white;
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cardTitle {
  font-size: 36px;
  margin: 0;
  color: rgb(204, 204, 204);
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.iconContainer {
  display: flex;
  gap: 10px;
}

.icon {
  font-size: 1.2rem;
}

.description {
  color: rgb(122, 122, 122);
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
}

.rating {
  color: #4caf50;
  font-size: 1.2rem;
  width: 180px;
}

.star {
  margin-right: 2px;
  height: 27px;
  width: 28px;
}

@media (max-width: 480px) {
  .cardTitle {
    font-size: 20px !important;
  }
}
