.test-cases-container {
  /* padding: 20px; */
  background-color: #111a19;
}

.test-case-header {
  color: #868c8a;
  margin-bottom: 20px;
  font-size: 14px;
  margin-top: 20px;
}

.test-case-card {
  display: grid;
  grid-template-columns: 1fr 1fr 0px;
  gap: 20px;
  background: #131514;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  margin-right: 15px;
}

.input-section,
.output-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.testcase-label {
  color: #888 !important;
  background-color: transparent !important;
  font-size: 14px;
}

.test-case-textarea {
  width: 100%;
  min-height: 100px;
  height: 176px;
  background: #333;
  border: 1px solid transparent;
  border-radius: 12px;
  color: white;
  padding: 10px;
  resize: vertical;
}

.test-case-textarea:focus {
  background-color: #1a1a1a;
  color: #e2f0e7;
  border: 1px solid var(--secondary-border-color) !important;
  outline: none;
}

.test-case-textarea::placeholder {
  color: #666666;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.control-button {
  background: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  transition: color 0.2s;
}

.control-button:hover {
  color: white;
}

.control-button.expand {
  font-size: 20px;
}

.control-button.delete {
  color: #666;
}

.control-button.delete:hover {
  color: #ff4444;
}

.add-option-btn {
  background: none;
  border: none;
  color: #b4ff45;
  padding: 8px 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.add-option-btn:hover {
  text-decoration: underline;
}
