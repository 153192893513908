.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-card {
  background-color: #191C1B;
  border-radius: 15px;
  padding: 2rem;
  width: 90%;
  max-width: 480px;
  position: relative;
  color:#E2F0E7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modal-header {
  text-align: center;
 
  margin-bottom: 1.5rem;
}

.modal-header h2 {
  margin: 0;
  color:#E2F0E7;
  font-weight: 600;
  font-size: 1.5rem;
}

.star-rating {
  display: flex;
}

.star-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.2s;
}

.star-button:hover {
  transform: scale(1.1);
}

.star-icon {
  width: 32px;
  height: 32px;
}

.star-filled .star-path {
  fill: #B4FF45;
}

.star-empty .star-path {
  fill: #e4e5e9;
}

.rating-text {
  text-align: center;
  color: #666;
  margin: 1rem 0;
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.button {
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.button-skip {
  width:218px !important;
  height:52px !important;
  background-color: #f0f0f0;
  color:#B4FF45;
  background-color: transparent;
  border:solid 1px #B4FF45 !important;
  justify-content: center;
}

.button-skip:hover {
  background-color: #e4e4e4;
  color:#131514
}

.submit-rating-button {
  background-color: #B4FF45;
  color: #131514;
  width:218px !important;
  height:52px !important;
  justify-content: center;
}

.submit-rating-button:hover {
  background-color: #9FE53C !important;
  color: #000 !important;
}

.button-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.feedback-section {
  margin: 1.5rem 0;
}

.feedback-section label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
  font-size: 0.9rem;
}

.feedback-section textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e4e5e9;
  border-radius: 8px;
  resize: vertical;
  min-height: 80px;
  font-family: inherit;
  font-size: 0.9rem;
  line-height: 1.5;
  transition: border-color 0.2s;
  background-color: #131514;
  color: #E2F0E7;
}

.feedback-section textarea:focus {
  outline: none;
  border-color: #4CAF50;
}

.feedback-section textarea::placeholder {
  color: #999;
}

