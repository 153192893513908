.terms-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.terms-header {
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
}

.terms-content {
  /* max-width: 800px; */

  margin: 100px 0;
  padding: 0 20px;
  min-height: calc(100vh - 180px);
}

.terms-content h1 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.terms-section {
  margin-bottom: 40px;
}

.terms-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #444;
}

.terms-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.error {
  text-align: center;
  padding: 2rem;
  color: #ff4444;
  font-size: 1.2rem;
}

.terms-date {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  font-style: italic;
}

.terms-section {
  line-height: 1.6;
}

.terms-section h2 {
  font-size: 1.8rem;
  margin: 2rem 0 1rem;
  color: #333;
}

.terms-section h3 {
  font-size: 1.4rem;
  margin: 1.5rem 0 1rem;
  color: #444;
}

.terms-section p {
  margin-bottom: 1rem;
  color: #555;
}

.terms-section ul {
  margin: 1rem 0;
  padding-left: 2rem;
}

.terms-section li {
  margin-bottom: 0.5rem;
  color: #555;
}
