.test-pagiation {
    gap: 6px
}

button.test-pagination-btn{
    position: relative;
    padding: 8px;
}
  
.paginationBtns {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.submittedQnFlag {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #B4FF45;
    border-top-right-radius: 6px;
}
  
.rightImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.test-page-action-btn {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: center;
}

.test-page-qn-type {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: var(--Neutral-Greys-500, #868C8A);
}

button:disabled {
    pointer-events: none;
}

/* Filter Dropdown Stlyes */
.viewAllQnPageDropdown {
    border: none;
    background-color: transparent;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    
}

.viewAllQnPageDropdown button {
    color: var(--Neutral-Greys-500, #868C8A);
    background-color: transparent;
    padding: 0;
}