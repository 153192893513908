 .question-type {
     margin-top: 10px;
     border: none;
     background-color: transparent;
     font-family: Inter;
     font-size: 14px;
     font-weight: 400;
     line-height: 16.94px;
     text-align: left;

     button {
         color: var(--Neutral-Greys-500, #868C8A);
         background-color: transparent;
         padding: 0;
     }
 }
 .dropdown-menu {
     width: 200px;
     &.show {
         display: flex;
         flex-direction: column;
         background-color: black;
         color: #868c8a;
         height: auto;
         overflow-y: scroll;

         &::-webkit-scrollbar {
             width: 8px;
         }

         &::-webkit-scrollbar-track {
             background: #191C1B;
             border-radius: 4px;
         }

         &::-webkit-scrollbar-thumb {
             background: #404342;
             border-radius: 4px;

             &:hover {
                 background: #868C8A;
             }
         }

         .dropdown-item {
             height: 40px;
             padding: 12px;

             &:hover {
                 background-color: #191c1b;
                 color: #868c8a;
             }
         }
     }
 }