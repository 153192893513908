.appNav {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 100;
    padding: 6px;
    margin-bottom: 0px;
    box-shadow: 0px 0px 10px rgba(92, 92, 92, 0.45);
    background: var(--primary-background-color);
}

.profilePopupBg {
    height: 100%;
    width: 100%;
    background: var(--shadow);
    position: absolute;
    z-index: 100;
}

.profilePopup {
    position: absolute;
    background: var(--secondary-background-color);
    padding: 16px;
    right: 10px;
    top: 70px;
    border-radius: 10px;
    z-index: 101;
}