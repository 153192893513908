.mcqQuestionPanel {
  display: flex;
  flex-direction: row;
  margin: 10% 5% 0% 10%;
  gap: 3%;

  .mcqQuestionDesc {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: #f8fafc;
  }

  .mcqOptions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .mcqOptionItemsTitle {
      font-family: "Inter";
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      text-align: left;
      text-transform: uppercase;
      color: #868c8a;
    }

    .mcqOptionItems {
      display: flex;
      align-items: center;
      gap: 12px;

      list-style-type: none;
      padding: 24px;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      background-color: #191c1b;
      border-radius: 16px;

      input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid var(--Neutral-Greys-500, #868c8a);
        cursor: pointer;
        position: relative;

        &:checked {
          &::after {
            content: url("../../assets/images/radioChecked.svg");
            position: absolute;
            left: -1px;
            top: -1px;
            width: 20px;
            height: 20px;
          }
        }
      }

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        &:checked {
          &:after {
            display: flex;
            justify-content: center;
            align-items: center;
            content: url("../../assets/images/checkboxTickMark.svg");
            background-color: #b4ff45;
            color: #131514;
            width: 22px;
            height: 22px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
