.faq-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.faq-header {
  color: rgb(191, 253, 86);
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.faq-icon {
  margin-right: 0.5rem;
}

.faq-title {
  font-size: 2.8rem;
  margin-bottom: 2rem;
  color: #cccccc;
  font-family: Poppins, "Poppins Placeholder", sans-serif;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-toggle {
  font-size: 1.5rem;
  color: rgba(122, 122, 122, 0.9);
  font-family: sans-serif;
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.7);
}

.ans-description {
  color: rgba(122, 122, 122, 0.9) !important;
  font-size: 18px;
  white-space: pre-line;
  line-height: 1.5;
  /* margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease; */
}

.faq-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
}

.faq-item.open .faq-answer {
  max-height: 500px;
  text-align: left;

  transition: max-height 0.5s ease-in, padding 0.3s ease-in;
}

.faq-answer p {
  padding: 1rem;
  margin: 0;
  color: #cccccc;
}

.faq-footer {
  margin-top: 2rem;
  text-align: center;
  color: rgb(122, 122, 122);
  font-size: 18px;
  font-family: Inter, "Inter Placeholder", sans-serif;
}

.faq-footer a {
  /* color:rgb(204, 204, 204); */
  color: rgb(204, 204, 204);

  text-decoration: none;
}

.faq-footer a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .faq-container {
    padding: 15px;
  }

  .faq-answer {
    padding: 0 15px;
  }

  .faq-question {
    font-size: 12px;
    padding: 20px;
  }

  .faq-item.open .faq-answer {
    padding: 0 15px 15px;
  }

  .ans-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .faq-container {
    padding: 10px;
  }
}
