.account-heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;
}

.avatar {
  padding: 8px;
  border-radius: 50%;
  background-color: #c3c3c3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--primary-text-color);
}

.user-email {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primary-text-color);
}

.signout {
  color: #ef4444;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.role-chip {
  padding: 2px 8px;
  background: #fff6cc;
  color: #665300;
  border-radius: 12px;
  border: 1px solid #6653001a;

  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.recruiters-tabs {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recruiters-tabs .tab {
  padding: 8px 16px;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
  color: #868c8a;
}
.recruiters-tabs .tab.active {
  /* background: var(--primary-text-color); */
  /* color: var(--secondary-background-color); */
  color: #b4ff45;
  border-radius: initial;
  border-bottom: 2px solid #b4ff45;
}

.profile-dropdown {
  position: relative;
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  padding: 4px;
  transition: background-color 0.3s;
}

.profile-button:hover {
  background-color: #333;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #eab308;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 500;
}

.email {
  font-size: 14px;
  color: #d1d5db;
}

.chevron {
  width: 16px;
  height: 16px;
  color: #9ca3af;
}

.profile-dropdown {
  position: absolute;
  z-index: 99999;
  right: 0;
  margin-top: 65px;
  width: 420px;
  border-radius: 8px;
  background-color: #1a1a1a;
  border: 1px solid #333;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.dropdown-content {
  padding: 16px;
}

.dropdown-title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 16px;
}

.account-info,
.recruiter-company-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.account-avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #eab308;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 500;
}

.account-details,
.recruiter-company-details {
  display: flex;
  flex-direction: column;
}

.account-name {
  font-weight: 500;
  color: #fff;
}

.account-email {
  font-size: 14px;
  color: #9ca3af;
}

/* .company-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
} */

.company-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ef4444;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-icon svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.company-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-label {
  font-size: 14px;
  color: #9ca3af;
}

.company-name {
  font-weight: 500;
  color: #fff;
}

.edit-button {
  font-size: 14px;
  font-weight: 600;
  color: #868c8a;
}

.edit-button:hover {
  color: #60a5fa;
}

.theme-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.theme-details {
  display: flex;
  align-items: center;
  gap: 12px;
}

.theme-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1f2937;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-icon svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.theme-text {
  display: flex;
  flex-direction: column;
}

.theme-label {
  font-size: 14px;
  color: #9ca3af;
}

.theme-value {
  font-weight: 500;
  color: #fff;
}

.other-settings {
  font-size: 14px;
  color: #9ca3af;
  margin-bottom: 16px;
}

.user-details-button {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.user-details {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #d1d5db;
  margin-bottom: 16px;
  text-decoration: none;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}

.user-details-button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ef4444;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  padding: 0;
}

.logout-button:hover {
  color: #f87171;
  background-color: rgba(239, 68, 68, 0.1);
}

.logout-text {
  color: #ef4444;
  font-weight: 600;
  margin-left: 5px;
}

.break-line {
  border: solid 1px #2d2e2e;
  margin-bottom: 16px;
}
